import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DropoffService } from "../../../service/company/dropoff.service";

const dropoffService = new DropoffService();

export const initialState = {
  dropoffs: Array(),
  loading: false,
};

export const fetchDropoffs = createAsyncThunk(
  "dropoff/fetch",
  async (thunkAPI) => {
    return dropoffService.getList();
  }
);

export const createDropoff = createAsyncThunk(
  "dropoff/post",
  async (data: Object, thunkAPI) => {
    return dropoffService.post(data);
  }
);

export const dropoffSlice = createSlice({
  name: "dropoffData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDropoffs.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchDropoffs.fulfilled, (state, action) => {
      state.dropoffs = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchDropoffs.rejected, (state, action) => {
      state.dropoffs = [];
      state.loading = false;
    });
    builder.addCase(createDropoff.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createDropoff.fulfilled, (state, action) => {
      state.loading = false;
    });
  },
});

// Action creators are generated for each case reducer function
// export const { update } = dropoffSlice.actions;

export default dropoffSlice.reducer;
