import { useEffect, useState } from "react";
import { LoadingComponent } from "../../shared/Loading";
import { Modal } from "../Modal";
import { blanccoService } from "../../../service/integrations/blancco.service";
import { Box, Flex } from "@chakra-ui/react";
import { pdfjs } from "react-pdf";
import { ButtonCustom } from "../../form/controls/form.button";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js`;

export const PreviewDescrutionReportModal = ({
  blanccoReportId,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [reportUrl, setReportUrl] = useState(null);

  const getReportBlobUrl = async () => {
    const resp = await blanccoService.getReportPdf(blanccoReportId);

    const buff = [new Uint8Array(resp.data.data)];
    const blob = new Blob(buff, {
      type: "application/pdf",
    });

    const url = window.URL.createObjectURL(blob);
    setReportUrl(url);

    return url;
  };

  const fetchReport = async () => {
    setLoading(true);
    setLoadError(false);

    await getReportBlobUrl()
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoadError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const ErrorComponent = () => {
    return (
      <Flex
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        textAlign="center"
        gap={4}
      >
        <Box>
          <p>Sorry, we are unable to load the Erasure Report.</p>
          <p>If this issue persists, please contact support.</p>
        </Box>
        <ButtonCustom neutral onClick={fetchReport}>
          Try again
        </ButtonCustom>
      </Flex>
    );
  };

  useEffect(() => {
    if (blanccoReportId) {
      fetchReport();
    }
  }, [blanccoReportId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Blancco Erasure Report"
      width="900px"
      height="700px"
    >
      {loading && <LoadingComponent color={"brand.green"} />}
      {!loading && !loadError && reportUrl && (
        <iframe
          src={reportUrl}
          style={{ width: "100%", height: "100%", overflow: "auto" }}
        >
          <ErrorComponent />
        </iframe>
      )}

      {loadError && <ErrorComponent />}
    </Modal>
  );
};
