import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../../store";
import AppLayout from "../layout/app.layout";

// Check to see if token is not empty. Authorization done in service calls.
export const AuthenticatedRoute = (props: { login?: boolean }) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.token);

  // Get from store
  const authenticated = props.login
    ? !!token.loginToken?.length
    : !!token.accessToken?.length;

  // If authorized, return an outlet passed from parent
  return authenticated ? (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ) : (
    <Navigate to="/app/auth/login" />
  );
};
