import { InventoryBulkFilter } from "../../models/filter/inventory-bulk.filter";
import { InventoryModel } from "../../models/inventory.model";
import { BaseService } from "../base.service";

export class InventoryService extends BaseService {
  constructor() {
    super();
  }

  async get(id: string): Promise<InventoryModel> {
    const resp = await this.client.get(`/company/inventory?id=${id}`);
    return resp.data;
  }

  async getList(
    filter?: InventoryBulkFilter,
    hideSold?: boolean,
    page?: number,
    pageSize?: number,
    sort?: {
      accessor: string;
      direction: 0 | 1;
    }
  ): Promise<{
    data: InventoryModel[];
    totalItems: number;
    totalPage: number;
  }> {
    const resp = await this.client.post(`/company/inventory/list`, {
      filter,
      hideSold,
      page,
      pageSize,
      sort,
    });
    return resp.data;
  }

  async exportToCsv(filter?: InventoryBulkFilter): Promise<any> {
    const resp = await this.client.post(
      `/company/inventory/list/export`,
      { filter },
      { responseType: "blob" }
    );
    return resp.data;
  }
}

export const inventoryService = new InventoryService();
