import { CustomFormModel } from "../../models/form-builder/custom-form.model";
import { BaseService } from "../base.service";

export class FormBuilderService extends BaseService {
  constructor() {
    super();
  }

  async get(id: string): Promise<CustomFormModel> {
    const resp = await this.client.get(
      `/company/inventory/form?customFormId=${id}`
    );
    return resp.data;
  }
}

export const formbuilderService = new FormBuilderService();
