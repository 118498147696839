import { JobStatus } from "../../models/enum/job-status.enum";
import { BaseService } from "../base.service";

export class JobService extends BaseService {
  constructor() {
    super();
  }

  async get(id: string) {
    const resp = await this.client.get(`/company/job?id=${id}`);
    return resp.data;
  }

  async getList(filter?: { staatus?: JobStatus; incompleteOnly?: boolean }) {
    const resp = await this.client.post(`/company/job/list`, { filter });
    return resp.data;
  }
}

export const jobService = new JobService();
