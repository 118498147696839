import { BaseService } from "../base.service";

export class ReportService extends BaseService {
  constructor() {
    super();
  }

  async downloadAcknowledgementReport(jobId: string) {
    const resp = await this.client.get(
      `/company/report/job/acknowledgement?jobId=${jobId}`
    );

    return resp.data;
  }

  async downloadItadReport(jobId: string) {
    const resp = await this.client.get(
      `/company/report/job/itad?jobId=${jobId}`
    );

    return resp.data;
  }
}
