import {
  Box,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

export const Modal = ({
  children,
  header,
  isOpen,
  onClose,
  width = undefined,
  height = undefined,
}) => (
  <ChakraModal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent mx="12px" maxWidth={width} height={height}>
      <ModalHeader>
        <Box
          color="brand.green.200"
          fontSize="22px"
          style={{ fontFamily: "zolo-header" }}
          fontWeight="700"
        >
          {header}
        </Box>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>{children}</ModalBody>
    </ModalContent>
  </ChakraModal>
);
