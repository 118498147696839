import { useEffect, useState } from "react";
import { Box, Flex, Tbody, Td, Text, Tr, useToast } from "@chakra-ui/react";
import { jobService } from "../../../service/job/job.service";
import { convertIsoTime } from "../../../util/date.util";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToggleInput } from "../../../components/form/controls/toggle.input";
import { JobStatus } from "../../../models/enum/job-status.enum";
import { StatusPickerInput } from "../../../components/form/controls/status-picker.input";
import PopoverCustom from "../../../components/shared/PopoverCustom";
import { TableContainer } from "../../../components/shared/table/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { sortDirection } from "../../../util/list.util";
import { Loading } from "../../../components/shared/Loading";
import { update } from "../../../store/slice/Account/job.slice";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { EmptyContent } from "../../../components/shared/EmptyContent";

interface Column {
  header: string;
  width?: string;
  accessor: string;
  callback?: (arg0?: any, arg1?: any) => React.ReactNode;
}

export const Job = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jobStore = useSelector((state: RootState) => state.job);

  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([] as any[]);
  const [toggled, setToggled] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);

  useEffect(() => {
    if (searchParams.get("searchTerm")) {
      setSearchTerm(searchParams.get("searchTerm"));
    }
  }, [searchParams.get("searchTerm")]);

  const columns: Column[] = [
    {
      header: "Ref #",
      width: "75px",
      accessor: "jobNo",
      callback: (jobNo: string, job: any) => (
        <Flex
          flexDir="row"
          gap="1"
          alignItems="center"
          fontWeight="600"
          color="brand.green"
        >
          {`${jobNo}`}
        </Flex>
      ),
    },
    {
      header: "Status",
      width: "100px",
      accessor: "status",
      callback: (status: string, job: any) => (
        <StatusPickerInput
          key={job.id}
          id={job.id}
          status={status}
          options={JobStatus}
        />
      ),
    },
    {
      header: "No. items",
      width: "125px",
      accessor: "totalInventory",
      callback: (totalInventory: string) => (
        <>{+totalInventory === 0 ? "-" : totalInventory}</>
      ),
    },
    {
      header: "Start date",
      width: "125px",
      accessor: "startDate",
    },
    {
      header: "Notes",
      width: "300px",
      accessor: "notes",
      callback: (notes) =>
        notes?.length && (
          <PopoverCustom
            width="300px"
            trigger={
              <Box
                display="flex"
                alignItems="center"
                height="100%"
                px={6}
                _hover={{
                  bgColor: "gray.200",
                }}
                cursor="pointer"
              >
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {notes}
                </Text>
              </Box>
            }
          >
            <Text whiteSpace="normal">{notes}</Text>
          </PopoverCustom>
        ),
    },
  ];

  const handleSort = (props: { accessor: string; direction: 0 | 1 }) => {
    dispatch(
      update({
        sortColumn: { accessor: props.accessor, direction: props.direction },
      })
    );

    setJobs(sortDirection(jobs, props.accessor, props.direction));
  };

  const init = async () => {
    setLoading(true);
    const filter = {
      incompleteOnly: !toggled,
      status: toggled ? "COMPLETE" : undefined,
      searchTerm,
    };

    await jobService
      .getList(filter)
      .then((jobs) => {
        let data = jobs.map((job) => {
          return {
            ...job,
            startDate: convertIsoTime(job.startDate),
          };
        });

        if (jobStore?.sortColumn?.accessor?.length)
          data = sortDirection(
            data,
            jobStore?.sortColumn?.accessor,
            jobStore?.sortColumn?.direction
          );

        setJobs(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, [toggled, searchTerm]);

  return (
    <>
      <Loading loading={loading} />

      <Flex flexDirection="column">
        <Flex my="13px" flexDirection="row" justifyContent="flex-end">
          <ToggleInput
            style={{ justifyContent: "flex-end" }}
            leftLabel="Progress"
            rightLabel="Complete"
            onChange={(e) => {
              setToggled(e.target.checked);
            }}
          />
        </Flex>

        <TableContainer
          columns={columns}
          onSort={handleSort}
          sortColumn={jobStore?.sortColumn}
          isEmpty={!jobs.length && !loading}
          emptyChildren={
            <Flex
              justifyContent="center"
              alignItems="center"
              minH="100%"
              minW="100%"
              border="1px solid #ECECEC"
              borderRadius="lg"
            >
              <EmptyContent
                title={toggled ? "No completed jobs" : "No current jobs"}
                description={
                  toggled
                    ? "All completed jobs will show here."
                    : "When a new job is created it will show here."
                }
              />
            </Flex>
          }
        >
          <Tbody zIndex={9}>
            {jobs.map((row, i) => (
              <Tr
                _hover={{
                  cursor: "pointer",
                  backgroundColor: "#ECECEC",
                }}
                cursor="pointer"
                onClick={() => navigate(`/app/job/${row.id}`)}
                key={row.id}
              >
                {columns.map((column, j) => (
                  <Td
                    key={j}
                    height="40px"
                    width={column.width ?? "full"}
                    p={column.accessor === "notes" && 0}
                  >
                    {!column.callback
                      ? row[column.accessor]
                      : column.callback(row[column.accessor], row)}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </TableContainer>
      </Flex>
    </>
  );
};
