import { Role } from "../../models/enum/role.enum";
import { BaseService } from "../base.service";

export class IdentityService extends BaseService {
  constructor() {
    super();
  }

  // Note: JWT contains email address used for query
  async userProfile() {
    const resp = await this.client.get(`/identity/user/profile`);

    return resp.data;
  }

  async exists(email: string) {
    const resp = await this.client.post("/identity/user/verify/login", {
      email: email,
    });

    return resp?.data;
  }

  async login(didToken: string) {
    const resp = await this.client.post("/identity/user/login", undefined, {
      headers: { Authorization: `Bearer ${didToken}` },
    });

    return resp.data;
  }

  //** Get user when only authenticated with magic.link */
  async getPublicUser(didToken: string) {
    const resp = await this.client.post("/identity/user/public", null, {
      headers: { Authorization: `Bearer ${didToken}` },
    });

    return resp.data;
  }

  async register(email: string, didToken: string) {
    return await this.client.post(
      "/identity/user",
      {
        email: email,
      },
      {
        headers: { Authorization: `Bearer ${didToken}` },
      }
    );
  }

  async deactivate(email: string) {
    await this.client.post("/identity/user/deactivate", { email: email });
  }
}
