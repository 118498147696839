import {
  Flex,
  Tr,
  Tbody,
  Td,
  Box,
  Text,
  useToast,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { InventoryBulkFilter } from "../../../models/filter/inventory-bulk.filter";
import { InventoryModel } from "../../../models/inventory.model";
import { StatusPickerInput } from "../../form/controls/status-picker.input";
import PopoverCustom from "../../shared/PopoverCustom";
import { TableContainer } from "../../shared/table/TableContainer";
import { useAppSelector } from "../../../util/hooks.util";
import { useDispatch, useSelector } from "react-redux";
import { DownloadIcon } from "@chakra-ui/icons";
import {
  updateJobSort,
  updateSort,
} from "../../../store/slice/Account/inventory.slice";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { hasNonNullProperty } from "../../../util/object.util";
import { CheckboxInput } from "../../form/controls/checkbox.input";
import { InventoryStatus } from "../../../models/enum/inventory/inventory-status.enum";
import { InventoryFilterModal } from "./inventory-filter.modal";
import { PreviewDescrutionReportModal } from "./preview-destruction-report.modal";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import { InventoryPreviewDrawer } from "./inventory-preview.drawer";
import { EmptyContent } from "../../shared/EmptyContent";

interface Column {
  header: string | JSX.Element;
  width?: string;
  accessor: string;
  disableSort?: boolean;
  isCustom?: boolean;
  callback?: (arg0: any, arg1?: any) => JSX.Element;
}

export const InventoryTable: React.FC<Props> = (props: Props) => {
  const {
    inventory,
    footer,
    isJobDetail,
    onCheckChange,
    onFilterChange,
    onPageWidthChange,
    onSort,
  } = props;

  const toast = useToast();
  const dispatch = useDispatch();
  const isLargeDisplay = useBreakpointValue({
    base: false,
    sm: false,
    md: true,
    lg: true,
    xl: true,
  });

  const { isManager } = useSelector((state: any) => state.userProfile);
  const { filter, jobFilter, sortColumn, jobSortColumn } = useAppSelector(
    (state) =>
      state.inventory ?? {
        filter: {},
        sortColumn: {},
        jobFilter: {},
        jobSortColumn: {},
      }
  );

  const [focusedInventory, setFocusedInventory] = useState([
    {},
  ] as InventoryModel[]);

  const [inventoryDrawer, setInventoryDrawer] = useState({
    isOpen: false,
    inventory: null,
  });
  const [filterModal, setFilterModal] = useState(false);
  const [destructionReport, setDestructionReport] = useState({
    isOpen: false,
    blanccoReportId: null,
  });

  // checkbox
  const [checkedItems, setCheckedItems] = useState([{}] as {
    id: string;
    isChecked: boolean;
  }[]);

  const allChecked = checkedItems.map((x) => x.isChecked).every((x) => !!x);
  const isIndeterminate =
    checkedItems.map((x) => x.isChecked).some(Boolean) && !allChecked;

  const handleBulkFilter = async (filter: InventoryBulkFilter) => {
    const hasVal = hasNonNullProperty(filter);
    if (hasVal) {
      onFilterChange(filter);
    }
  };

  useEffect(() => {
    if (onCheckChange) {
      onCheckChange(checkedItems);

      const focusedInventory = inventory.filter((x) =>
        checkedItems
          .filter((y) => x.id === y.id && y.isChecked)
          .map((z) => z.id)
          .includes(x.id)
      );

      setFocusedInventory(focusedInventory);
    }
  }, [checkedItems]);

  const handleSort = (props: { accessor: string; direction: 0 | 1 }) => {
    dispatch(
      isJobDetail
        ? updateJobSort({
            jobSortColumn: props,
          })
        : updateSort({
            sortColumn: props,
          })
    );

    if (onSort) onSort(props);
  };

  const handleCheck = ({ id, isChecked }) => {
    setCheckedItems((prevItems) => {
      if (isChecked) {
        return [...prevItems, { id, isChecked }];
      } else {
        return prevItems.filter((item) => item.id !== id);
      }
    });
  };

  const columns: Column[] = [
    {
      header: (
        <CheckboxInput
          isChecked={allChecked}
          isIndeterminate={isIndeterminate}
          onChange={(e) =>
            setCheckedItems(
              inventory.map((x) => ({ id: x.id, isChecked: e.isChecked }))
            )
          }
        />
      ),
      disableSort: true,
      width: "10px",
      accessor: "id",
      callback: (id: string) => (
        <CheckboxInput
          id={id}
          isChecked={!!checkedItems.find((x) => x.id === id)?.isChecked}
          onChange={handleCheck}
        />
      ),
    },
    {
      header: "Status",
      width: "75px",
      accessor: "status",
      callback: (status: string, row: InventoryModel) => (
        <StatusPickerInput
          id={row.id}
          status={status}
          options={InventoryStatus}
        />
      ),
    },
    {
      header: "Job",
      accessor: "jobId",
      width: "60px",
      callback: (jobId) => {
        return isJobDetail ? (
          <></>
        ) : (
          <Box color="brand.green" cursor={"pointer"}>
            <LaunchOutlinedIcon
              onClick={() =>
                window.open(
                  `/app/job/${jobId}`,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            />
          </Box>
        );
      },
    },
    { header: "Category", width: "100px", accessor: "category" },
    { header: "Make", width: "75px", accessor: "make" },
    { header: "Model", width: "100px", accessor: "model" },
    { header: "Serial", width: "120px", accessor: "serialNumber" },
    { header: "Processor", width: "120px", accessor: "processor" },

    {
      disableSort: true,
      header: "",
      accessor: "id",
      width: "60px",
      callback: (id, row) => (
        <Flex flexDirection="row" gap="16px" justifyContent="flex-end">
          {row.blanccoReportId && (
            <Tooltip label="Data Erasure Report" aria-label="destruction">
              <DownloadIcon
                width="18px"
                height="18px"
                cursor="pointer"
                style={{
                  color:
                    row.blanccoReportId === destructionReport.blanccoReportId
                      ? "green"
                      : "gray",
                  marginTop: "4px",
                  marginLeft: "4px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setDestructionReport({
                    blanccoReportId: row.blanccoReportId,
                    isOpen: true,
                  });
                }}
              />
            </Tooltip>
          )}
          {isLargeDisplay && (
            <LoginOutlinedIcon
              width="18px"
              height="18px"
              cursor="pointer"
              style={{ color: "gray", marginTop: "4px", marginLeft: "4px" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setInventoryDrawer((prev) => {
                  let isOpen = !prev.isOpen;

                  if (!prev.isOpen) {
                    onPageWidthChange(isOpen ? "75%" : "100%");
                    return {
                      isOpen,
                      inventory: row,
                    };
                  }

                  if (prev.isOpen && row.id !== prev.inventory?.id) {
                    return {
                      isOpen: true,
                      inventory: row,
                    };
                  }

                  onPageWidthChange(isOpen ? "75%" : "100%");
                  return {
                    isOpen,
                    inventory: row,
                  };
                });
              }}
            />
          )}
        </Flex>
      ),
    },
  ];

  const tableColumns = useMemo(() => {
    let copy = columns;

    if (!isManager) {
      copy = copy.filter(
        (x) => x.accessor !== "salePrice" && x.accessor !== "quotePrice"
      );
    }

    return isJobDetail ? copy?.filter((x) => x.accessor !== "jobId") : copy;
  }, [columns, isManager, isJobDetail]);

  return (
    <Flex flexDirection="column">
      <Flex
        px="16px"
        flexDir="row"
        color="brand.green"
        justifyContent="flex-start"
        h="35px"
        gap="16px"
      >
        <Flex flexDir="column" position="relative">
          {Object.keys(isJobDetail ? jobFilter : filter).length ? (
            <FilterAltIcon
              onClick={() => setFilterModal(true)}
              style={{
                color: "gray",
                cursor: "pointer",
                fontSize: "22px",
              }}
            />
          ) : (
            <FilterAltOffIcon
              onClick={() => setFilterModal(true)}
              style={{
                color: "gray",
                cursor: "pointer",
                fontSize: "22px",
              }}
            />
          )}
        </Flex>
      </Flex>

      {inventory.length ? (
        <>
          <TableContainer
            key={inventory.length}
            columns={tableColumns}
            sortColumn={isJobDetail ? jobSortColumn : sortColumn}
            onSort={handleSort}
            h="74vh"
          >
            <Tbody>
              {inventory.map((row, i) => (
                <Tr
                  key={i}
                  style={
                    checkedItems
                      .filter((x) => x.isChecked)
                      .map((x) => x.id)
                      .includes(row.id)
                      ? { backgroundColor: "#ECECEC" }
                      : {}
                  }
                  color={row.deleted ? "gray.300" : undefined}
                  backgroundColor={
                    row.id === inventoryDrawer.inventory?.id &&
                    inventoryDrawer.isOpen
                      ? "green.100"
                      : undefined
                  }
                  height="100%"
                >
                  {tableColumns.map((column, j) => {
                    if (column.isCustom) {
                      return (
                        <Td key={j} width={column.width}>
                          {row.customFieldsJson[column.accessor]}
                        </Td>
                      );
                    }

                    return (
                      <Td key={j} width={column.width}>
                        {column.callback
                          ? column.callback(row[column.accessor], row)
                          : row[column.accessor]}
                      </Td>
                    );
                  })}
                </Tr>
              ))}
            </Tbody>
          </TableContainer>
          {footer}
        </>
      ) : (
        <Flex
          justifyContent="center"
          alignItems="center"
          minH="100%"
          minW="100%"
          border="1px solid #ECECEC"
          borderRadius="lg"
        >
          <EmptyContent description="No inventory items found for this job" />
        </Flex>
      )}

      <InventoryFilterModal
        isOpen={filterModal}
        isJobDetail={isJobDetail}
        onClose={() => setFilterModal(false)}
        onClear={() => onFilterChange({})}
        onSubmit={(data) => {
          handleBulkFilter(data);
          setFilterModal(false);
        }}
      />

      {!!destructionReport.blanccoReportId && (
        <PreviewDescrutionReportModal
          blanccoReportId={destructionReport.blanccoReportId}
          isOpen={destructionReport.isOpen}
          onClose={() =>
            setDestructionReport({ blanccoReportId: null, isOpen: false })
          }
          onSubmit={() => {}}
        />
      )}

      {inventoryDrawer.isOpen && (
        <InventoryPreviewDrawer
          inventory={inventoryDrawer.inventory}
          onOpen={() => {
            setInventoryDrawer({ isOpen: false, inventory: null });
            onPageWidthChange("100%");
          }}
          onOpenDestructionPreview={
            !inventoryDrawer.inventory?.blanccoReportId
              ? undefined
              : () => {
                  setDestructionReport({
                    blanccoReportId: inventoryDrawer.inventory.blanccoReportId,
                    isOpen: true,
                  });
                }
          }
        />
      )}
    </Flex>
  );
};

interface Props {
  inventory: InventoryModel[];
  isJobDetail?: boolean;
  onDelete?: (id: string) => void;
  onBulkDelete?: (id: string[]) => void;
  onPageWidthChange?: (size: string) => void; // px
  onEdit?: () => void;
  onCheckChange?: (data: { id: string; isChecked: boolean }[]) => void;
  onFilterChange?: (data: InventoryBulkFilter) => void;
  onSort?: (data: { accessor: string; direction: 0 | 1 }) => void;
  disableEdit?: boolean;
  footer?: any;
}
