import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import storage from "reduxjs-toolkit-persist/lib/storage";
import autoMergeLevel1 from "reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1";
import {
  persistCombineReducers,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "reduxjs-toolkit-persist";

// Slices
import dropoffSlice, {
  initialState as dropoffInitialState,
} from "./slice/Account/dropoff.slice";
import userProfileSlice, {
  update as userProfileUpdate,
  initialState as userProfileInitialState,
} from "./slice/Account/user-profile.slice";
import tokenSlice, {
  update as tokenUpdate,
  initialState as tokenInitialState,
} from "./slice/Token/token.slice";
import companySlice, {
  update as updateCompany,
} from "./slice/Account/company.slice";
import dashboardSlice, {
  dashboardInitialState,
  updateDashboard,
} from "./slice/Account/dashboard.slice";
import userRegistrationSlice, {
  updateUserRegistration,
  userRegistrationInitialState,
} from "./slice/Account/user-registration.slice";
import inventorySlice, {
  updateActiveForm,
} from "./slice/Account/inventory.slice";

const blacklist = ["userProfile"];

const persistConfig = {
  key: "root",
  timeout: 2000,
  storage: storage,
  stateReconciler: autoMergeLevel1,
  // blacklist,
};

const _persistedReducer = persistCombineReducers(persistConfig, {
  userProfile: userProfileSlice,
  token: tokenSlice,
  company: companySlice,
  userRegistration: userRegistrationSlice,
  dropoffData: dropoffSlice,
  dashboard: dashboardSlice,
  inventory: inventorySlice,
});

export const store = configureStore({
  reducer: _persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      /* ignore persistance actions */
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const destroyAllState = () => {
  store.dispatch(userProfileUpdate(userProfileInitialState));
  store.dispatch(tokenUpdate(tokenInitialState));
  store.dispatch(updateDashboard(dashboardInitialState));
  store.dispatch(updateUserRegistration(userRegistrationInitialState));
  store.dispatch(updateCompany({} as any));
};
