import { Box, Flex, ModalFooter } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { useEffect, useState } from "react";
import { DropdownInput } from "../../form/controls/dropdown.input";
import { FormInput } from "../../form/controls/form.input";
import { CompanyService } from "../../../service/company/company.service";
import { ToggleInput } from "../../form/controls/toggle.input";
import { DatepickerInput } from "../../form/controls/date-picker.input";
import { useAppSelector } from "../../../util/hooks.util";
import { useSelector } from "react-redux";
import { InventoryCondition } from "../../../models/enum/inventory/inventory-condition.enum";
import { UserService } from "../../../service/user/user.service";
import { InventoryType } from "../../../models/enum/inventory/inventory-type.enum";
import { InventoryStatus } from "../../../models/enum/inventory/inventory-status.enum";
import { formbuilderService } from "../../../service/forge/form-builder.service";
import { transformArrayToObject } from "../../../util/object.util";
import { InventoryCategory } from "../../../models/enum/inventory/inventory-category.enum";

export const InventoryFilterModal = ({
  isOpen,
  isJobDetail,
  onClose,
  onSubmit,
  onClear,
}) => {
  const userService = new UserService();

  const inventoryStore = useSelector((state: any) => state.inventory);
  const [formState, setFormState] = useState({} as any);
  const [companyDropdown, setCompanyDropdown] = useState([] as any);
  const [userDropdown, setUserDropdown] = useState([] as any);
  const [formJson, setFormJson] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  const init = async () => {
    const form = await formbuilderService.get(inventoryStore?.formId);
    setFormJson(form.formJson);
  };

  const onCompanyChange = (event: any) => {
    let { value } = event.target;
    setSelectedCompanyId(value);
    onInputChange(event);
  };

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const { pickupAfter, pickupBefore, createdAfter, createdBefore } =
      formState;

    // validate dates
    if (
      pickupBefore &&
      pickupAfter &&
      new Date(pickupBefore) < new Date(pickupAfter)
    ) {
      alert("Pickup before date must be greater than pickup after date");
      return;
    }

    if (
      createdBefore &&
      createdAfter &&
      new Date(createdBefore) < new Date(createdAfter)
    ) {
      alert("Create before date must be greater than created after date");
      return;
    }

    onSubmit(formState);
    handleClose();
  };

  useEffect(() => {
    init();
  }, []);

  const handleClose = () => {
    setFormState({});
    onClose();
  };

  useEffect(() => {
    if (inventoryStore?.filter) {
      if (isJobDetail) {
        setFormState(inventoryStore.jobFilter);
      } else {
        setFormState(inventoryStore.filter);
      }
    }
  }, [isOpen]);

  return (
    <Modal
      width="fit-content"
      isOpen={isOpen}
      header="Filter Inventory"
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <FormInput
            name="addressName"
            defaultValue={formState.addressName}
            title="Address"
            placeholder="Search by street name, state, postcode, etc."
            onChange={onInputChange}
          />
          <Flex
            flexDir="column"
            gap="12px"
            pb="24px"
            borderBottom="1px solid lightGray"
          >
            <Flex flexDir="row" gap="12px" w="100%">
              <DropdownInput
                styles={{ width: "100%" }}
                name="companyId"
                title="Company"
                defaultValue={formState?.companyId}
                options={companyDropdown}
                onChange={onCompanyChange}
              />
              <DropdownInput
                styles={{ width: "100%" }}
                name="contactUserId"
                title="Contact user"
                options={userDropdown}
                onChange={onInputChange}
              />
            </Flex>
            <Flex flexDir="row" gap="6px">
              <DropdownInput
                name="status"
                title="Status"
                options={
                  formJson
                    ? transformArrayToObject(
                        formJson?.find((x) => x.name === "status").options
                      )
                    : InventoryStatus
                }
                defaultValue={formState.status}
                onChange={onInputChange}
              />
              <DropdownInput
                name="type"
                title="Inventory Type"
                options={
                  formJson
                    ? transformArrayToObject(
                        formJson?.find((x) => x.name === "type").options
                      )
                    : InventoryType
                }
                defaultValue={formState.type}
                onChange={onInputChange}
              />
            </Flex>
            <Flex flexDir="row" gap="6px">
              <DropdownInput
                name="category"
                title="Category"
                options={
                  formJson
                    ? transformArrayToObject(
                        formJson?.find((x) => x.name === "category").options
                      )
                    : InventoryCategory
                }
                defaultValue={formState.category}
                onChange={onInputChange}
              />
              <DropdownInput
                name="condition"
                title="Condition"
                options={
                  formJson
                    ? transformArrayToObject(
                        formJson?.find((x) => x.name === "condition").options
                      )
                    : InventoryCondition
                }
                defaultValue={formState.condition}
                onChange={onInputChange}
              />
            </Flex>
            <Flex flexDir="row" gap="6px">
              <FormInput
                name="jobNo"
                defaultValue={formState.jobNo}
                title="Job Number"
                onChange={onInputChange}
              />
              <FormInput
                name="make"
                defaultValue={formState.make}
                title="Make"
                onChange={onInputChange}
              />
              <FormInput
                name="model"
                defaultValue={formState.model}
                title="Model"
                onChange={onInputChange}
              />
            </Flex>
            <Flex flexDir="row" gap="6px">
              <FormInput
                name="processor"
                title="Processor"
                defaultValue={formState.processor}
                onChange={onInputChange}
              />
              <FormInput
                name="ram"
                title="RAM"
                type="number"
                defaultValue={formState.ram}
                onChange={onInputChange}
              />
              <FormInput
                name="grade"
                defaultValue={formState.grade}
                title="Grade"
                onChange={onInputChange}
              />
            </Flex>
          </Flex>
          <Flex
            flexDir="row"
            justifyContent="space-between"
            pt="12px"
            gap="12px"
          >
            <DatepickerInput
              title="Pickup after"
              name="pickupAfter"
              initialDate={
                formState?.pickupAfter
                  ? new Date(formState?.pickupAfter)
                  : undefined
              }
              onChange={onInputChange}
            />
            <DatepickerInput
              title="Pickup before"
              name="pickupBefore"
              initialDate={
                formState?.pickupBefore
                  ? new Date(formState?.pickupBefore)
                  : null
              }
              onChange={onInputChange}
            />
          </Flex>
          <Flex
            flexDir="row"
            justifyContent="space-between"
            pb="24px"
            borderBottom="1px solid lightGray"
            gap="12px"
          >
            <DatepickerInput
              title="Created after"
              name="createdAfter"
              initialDate={
                formState?.createdAfter
                  ? new Date(formState?.createdAfter)
                  : null
              }
              onChange={onInputChange}
            />
            <DatepickerInput
              title="Created before"
              name="createdBefore"
              initialDate={
                formState?.createdBefore
                  ? new Date(formState?.createdBefore)
                  : null
              }
              onChange={onInputChange}
            />
          </Flex>
          <ToggleInput
            name="showDeleted"
            leftLabel="Show deleted"
            defaultChecked={formState?.showDeleted}
            onChange={onInputChange}
          />

          <ModalFooter>
            <Flex gap="12px">
              <ButtonCustom
                secondary
                neutral
                onClick={() => {
                  onClear();
                  onClose();
                }}
              >
                Clear
              </ButtonCustom>
              <ButtonCustom type="submit">Confirm</ButtonCustom>
            </Flex>
          </ModalFooter>
        </Flex>
      </form>
    </Modal>
  );
};
