import { AddressModel } from "../models/address.model";

export const formatGoogleLocation = (e: any) => {
  return {
    google_place_id: e.place_id,
    coordinates: {
      lat: e.geometry.location.lat(),
      lng: e.geometry.location.lng(),
    },
    name: e.formatted_address,
  } as unknown as AddressModel;
};
