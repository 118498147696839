import { CompanyModel } from "../../models/company.model";
import { BaseService } from "../base.service";

export class BranchService extends BaseService {
  constructor() {
    super();
  }

  async get(id: string): Promise<CompanyModel> {
    const resp = await this.client.get(`/company/branch?companyId=${id}`);
    return resp.data;
  }

  async getBranchList(): Promise<CompanyModel[]> {
    const resp = await this.client.get(`/company/branch/list`);
    return resp.data;
  }

  async getBranchesDashboard(): Promise<any> {
    const resp = await this.client.get(`/company/branch/list/dashboard`);
    return resp?.data;
  }

  async createBranch(data: any) {
    const resp = await this.client.post(`/company/branch`, data);
    return resp.data;
  }
}

export const branchService = new BranchService();
