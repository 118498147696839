import {
  Box,
  Flex,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState, destroyAllState } from "../../store";
import { useEffect, useRef, useState } from "react";
import { MagiclinkService } from "../../service/magiclink/magiclink.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loading } from "../shared/Loading";
import { companyService } from "../../service/company/company.service";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { CompostIcon } from "../../custom-assets";
import { FormInput } from "../form/controls/form.input";
import { useOutsideClick } from "../hooks/click-outside.hook";

const titles = [
  { header: "pick-up requests", uri: "dropoff" },
  { header: "account details", uri: "account" },
  { header: "zolosystem", uri: "dashboard" },
  { header: "jobs", uri: "job" },
  { header: "branches", uri: "branch" },
];

const Header: React.FC<any> = (props: any) => {
  const { leftOffset } = props;

  const navigate = useNavigate();

  const { credits, monthEmission } = useSelector(
    (state: RootState) => state.dashboard
  );

  const wrapperRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [hide, setHide] = useState(false);
  const [globalSearchResults, setGlobalSearchResults] = useState(
    [] as { title: string; type: "job" | "inventory" | "branch"; id: string }[]
  );

  const [inventorySearchResults, setInventorySearchResults] = useState(
    [] as { title: string; id: string; job_id: string }[]
  );
  const [branchSearchResults, setBranchSearchResults] = useState(
    [] as { title: string; id: string }[]
  );
  const [jobSearchResults, setJobSearchResults] = useState(
    [] as { title: string; id: string }[]
  );

  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDropdownOpen, setSearchDropdownOpen] = useState(false);

  const magiclinkService = new MagiclinkService();

  const isLargeDisplay = useBreakpointValue({
    base: false,
    sm: false,
    md: true,
    lg: true,
    xl: true,
  });

  const { photoUri, email, role } = useSelector(
    (state: RootState) => state.userProfile
  );

  const logout = async () => {
    setLoading(true);
    await magiclinkService
      .logout()
      .then(() => {
        destroyAllState();
      })
      .finally(() => {
        navigate("/");
        setLoading(false);
      });
  };

  const getEntityColor = (type: "job" | "inventory" | "branch") => {
    switch (type) {
      case "job":
        return "#8BBFEA";
      case "inventory":
        return "#A0CCA2";
      case "branch":
        return "#F6D981";
      default:
        return "#F6D981";
    }
  };

  useOutsideClick(wrapperRef, () => {
    setSearchDropdownOpen(false);
  });

  useEffect(() => {
    if (searchParams.get("searchTerm")) {
      setSearchTerm(searchParams.get("searchTerm"));
    }
  }, [searchParams.get("searchTerm")]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    for (let t of titles) {
      if (currentPath.endsWith(t.uri)) {
        setTitle(t.header);
        break;
      }
    }

    setHide(currentPath.endsWith("dashboard"));
  }, [window.location.pathname]);

  const handleGlobalSearch = async (e: any) => {
    const { value } = e.target;

    setSearchTerm(value);

    await companyService.globalSearch(value).then((res) => {
      setBranchSearchResults(res.data.filter((item) => item.type === "branch"));
      setInventorySearchResults(
        res.data.filter((item) => item.type === "inventory")
      );
      setJobSearchResults(res.data.filter((item) => item.type === "job"));
      setGlobalSearchResults(res.data);
    });

    setSearchDropdownOpen(true);
  };

  return (
    <Flex
      ref={wrapperRef}
      hidden={hide || !isLargeDisplay}
      h="60px"
      w="100%"
      bg="white"
      position="absolute"
      zIndex={5}
      flexDir="row"
      alignItems="center"
      justifyContent="flex-end"
      pl={`calc(36px + ${leftOffset}px)`}
      pr="36px"
      borderBottom="1px solid lightGray"
    >
      <Loading loading={loading} fill ignoreDelay />
      <Flex w="100%" flexDir="row" gap="6px" justifyContent="center">
        <Box w="600px" pos="relative" marginRight="100px">
          <FormInput
            name={"globalSearch"}
            title={""}
            value={searchTerm}
            placeholder="Start typing to find your jobs, inventory, and more..."
            onChange={handleGlobalSearch}
            onFocus={handleGlobalSearch}
          ></FormInput>

          {searchDropdownOpen && (
            <>
              <>
                <VStack
                  pos="absolute"
                  w="600px"
                  align="stretch"
                  mt="2"
                  boxShadow="sm"
                  border="1px solid #E2E8F0"
                  borderRadius="md"
                  bg="white"
                  px="3"
                  py="4"
                >
                  {globalSearchResults.length ? (
                    <>
                      {branchSearchResults.length === 5 && (
                        <Flex
                          cursor="pointer"
                          fontSize="12px"
                          justifyContent="flex-end"
                        >
                          Top 5 showing
                        </Flex>
                      )}
                      {branchSearchResults.map((item, index) => (
                        <>
                          <Flex
                            py="1"
                            px="2"
                            borderBottom={
                              index === branchSearchResults.length - 1
                                ? "1px solid #E2E8F0"
                                : ""
                            }
                            flexDir="row"
                            key={item.id}
                            justifyContent="space-between"
                            _hover={{
                              cursor: "pointer",
                              bg: "gray.200",
                            }}
                            borderRadius="4px"
                            onClick={() => {
                              // Make this open in a new tab
                              window.open(
                                `/app/branch/${item.id}?searchTerm=${searchTerm}`,
                                "_blank"
                              );
                            }}
                          >
                            <Box>{item.title}</Box>
                            <Box
                              fontSize="12px"
                              fontWeight="600"
                              h="20px"
                              px="12px"
                              bg={getEntityColor("branch")}
                              borderRadius="6px"
                            >
                              Branch
                            </Box>
                          </Flex>
                        </>
                      ))}
                      {jobSearchResults.length === 5 && (
                        <Flex
                          cursor="pointer"
                          fontSize="12px"
                          justifyContent="flex-end"
                        >
                          Top 5 showing
                        </Flex>
                      )}
                      {jobSearchResults.map((item, index) => (
                        <>
                          <Flex
                            py="1"
                            px="2"
                            flexDir="row"
                            key={item.id}
                            justifyContent="space-between"
                            borderBottom={
                              index === jobSearchResults.length - 1
                                ? "1px solid #E2E8F0"
                                : ""
                            }
                            _hover={{
                              cursor: "pointer",
                              bg: "gray.200",
                            }}
                            borderRadius="4px"
                            onClick={() => {
                              window.open(
                                `/app/job/${item.id}?searchTerm=${searchTerm}`,
                                "_blank"
                              );
                            }}
                          >
                            <Box>JOB-{item.title}</Box>
                            <Box
                              fontSize="12px"
                              fontWeight="600"
                              h="20px"
                              px="12px"
                              bg={getEntityColor("job")}
                              borderRadius="6px"
                            >
                              Job
                            </Box>
                          </Flex>
                        </>
                      ))}

                      {inventorySearchResults.length === 5 && (
                        <Flex
                          cursor="pointer"
                          fontSize="12px"
                          justifyContent="flex-end"
                        >
                          Top 5 showing
                        </Flex>
                      )}
                      {inventorySearchResults.map((item, index) => (
                        <>
                          <Flex
                            py="1"
                            px="2"
                            flexDir="row"
                            key={item.id}
                            justifyContent="space-between"
                            _hover={{
                              cursor: "pointer",
                              bg: "gray.200",
                            }}
                            borderRadius="4px"
                            onClick={() => {
                              // Make this open in a new tab
                              window.open(
                                `/app/job/${item.job_id}?inventory_id=${item.id}&searchTerm=${searchTerm}`,
                                "_blank"
                              );
                            }}
                          >
                            <Box>{item.title}</Box>
                            <Box
                              fontSize="12px"
                              fontWeight="600"
                              h="20px"
                              px="12px"
                              bg={getEntityColor("inventory")}
                              borderRadius="6px"
                            >
                              Inventory
                            </Box>
                          </Flex>
                        </>
                      ))}
                    </>
                  ) : (
                    <>Nothing to see</>
                  )}
                </VStack>
              </>
            </>
          )}
        </Box>

        <Menu>
          {/* <MenuButton
            as={SettingsIcon}
            color="brand.gray.400"
            sx={{ background: "none", color: "#8f8d88", cursor: "pointer" }}
            _hover={{ bg: "none" }}
            _expanded={{ bg: "none" }}
            _focus={{ bg: "none" }}
          /> */}
          <MenuList>
            <MenuGroup title={email}>
              <Flex
                flexDir="row"
                gap="14px"
                pl="12px"
                fontWeight="700"
                fontSize="14px"
                color="brand.gray.400"
              >
                <Flex alignItems="center" borderRadius="4px">
                  <MonetizationOnIcon
                    style={{ fontSize: "18px", marginRight: "4px" }}
                  />
                  {credits ?? 0}
                </Flex>
                <Flex alignItems="center" borderRadius="4px">
                  <Box style={{ fontSize: "18px", marginRight: "4px" }}>
                    <CompostIcon color="#8f8d88" />
                  </Box>
                  {monthEmission ?? 0}
                </Flex>
              </Flex>
              <MenuDivider />
              <MenuItem
                onClick={() => navigate("/app/account")}
                sx={{ fontWeight: "bold", fontSize: "14px" }}
              >
                Account
              </MenuItem>
              <MenuItem
                onClick={logout}
                sx={{ fontWeight: "bold", color: "red", fontSize: "14px" }}
              >
                Logout
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default Header;
