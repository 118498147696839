import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Progress,
  Flex,
  VStack,
  Spacer,
  Text,
  Badge,
  Center,
  keyframes,
  Image,
  Button,
} from "@chakra-ui/react";
import { CalendarIcon, ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { JobStatus } from "../../../models/enum/job-status.enum";
import ChecklistIcon from "@mui/icons-material/Checklist";
import InputOutlinedIcon from "@mui/icons-material/InputOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { ReportService } from "../../../service/job/report.service";
import { ButtonCustom } from "../../form/controls/form.button";
import { DownloadIcon } from "@chakra-ui/icons";

const pulse = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.4; }
  100% { opacity: 1; }
`;

export const JobProgress = ({ job, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const reportService = new ReportService();

  const [value, setValue] = useState(0 as 0 | 33 | 66 | 100);

  const handleAcknowledgement = async () => {
    const resp = await reportService.downloadAcknowledgementReport(job.id);

    const blob = new Blob([new Uint8Array(resp.data.data)], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `acknowledgement-report-${job.id}.xlsx`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleItad = async () => {
    const resp = await reportService.downloadItadReport(job.id);

    const blob = new Blob([new Uint8Array(resp.data.data)], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `itad-report-${job.id}.xlsx`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const init = () => {
    const val =
      job.status === "PENDING"
        ? 0
        : job.status === "COLLECTION"
        ? 33
        : job.status === "COMPLETE"
        ? 100
        : 66;
    setValue(val);
  };

  const isActive = (status: string) => {
    if (status === "REBATE" && job.status === "COMPLETE")
      return job.rebateRequired && job.rebateReady;

    return status.toLowerCase() === job.status.toLowerCase();
  };

  const getPulseAnimation = (status: string) => {
    return status.toLowerCase() === job.status.toLowerCase() ||
      (job.status === "COMPLETE" && job.rebateRequired && job.rebateReady)
      ? `${pulse} 2s infinite`
      : "none";
  };

  const bg = {
    background:
      "linear-gradient(180deg, rgba(120, 190, 136, 0.2) 0%, #78BE88 100%), linear-gradient(180deg, rgba(214, 249, 112, 0.2) 0%, rgba(9, 63, 21, 0.17) 100%), linear-gradient(0deg, #DEF9E4, #DEF9E4)",
  };

  useEffect(() => {
    init();
  }, []);

  const ProgressPill = ({ status, children }) => (
    <Flex
      bg={isActive(status) ? bg.background : undefined}
      color={isActive(status) ? "brand.green.300" : "brand.gray.400"}
      borderColor={isActive(status) ? "brand.green.300" : "brand.gray.400"}
      px="12px"
      borderRadius="32px"
      fontSize="14px"
      py="6px"
      border={`1px solid`}
      sx={{
        animation: getPulseAnimation(status),
        fontWeight: status === job.status ? "700" : undefined,
      }}
      flexDir="row"
      alignItems="center"
      gap="6px"
    >
      {children}
    </Flex>
  );

  return (
    <Accordion allowToggle defaultIndex={isOpen ? [0] : undefined}>
      <AccordionItem
        bg="#F5F6EF"
        sx={
          isOpen
            ? { border: "2px solid #d4d4cb", borderRadius: "8px" }
            : { border: "none", borderRadius: "8px" }
        }
      >
        <AccordionButton
          sx={
            isOpen
              ? {}
              : {
                  border: "1px solid #d4d4cb",
                  borderRadius: "8px",
                  padding: "22px",
                }
          }
          onClick={() => setIsOpen(!isOpen)}
        >
          <Flex
            flexDir="row"
            justifyContent={isOpen ? "flex-end" : "space-between"}
            w="100%"
          >
            <ProgressPreview job={job} isOpen={isOpen} />
            <Box>
              {isOpen ? (
                <ChevronUpIcon boxSize={6} />
              ) : (
                <ChevronDownIcon boxSize={6} />
              )}
            </Box>
          </Flex>
        </AccordionButton>
        <AccordionPanel p={4} pb="36px">
          <Flex
            position="relative"
            pb="26px"
            pt={isOpen ? "0" : undefined}
            flexDir="column"
            gap="20px"
          >
            <Flex
              bg="#EDEEE2"
              border="1px solid #CCD1AD"
              color="gray.400"
              p="8px"
              borderRadius="32px"
              flexDir={{ base: "column", md: "row" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <ProgressPill status={"PICKUP"}>
                <LocalShippingOutlinedIcon />
                <Text>Waiting pick-up</Text>
              </ProgressPill>
              <RightArrow />
              <ProgressPill status={"PENDING"}>
                <HourglassBottomIcon />
                <Text>Pending</Text>
              </ProgressPill>
              <RightArrow />
              <ProgressPill status={"COLLECTION"}>
                <InputOutlinedIcon />
                <Text>Collected</Text>
              </ProgressPill>
              <RightArrow />
              <ProgressPill status={"IN_PROGRESS"}>
                <TimelapseIcon />
                <Text>Processing</Text>
              </ProgressPill>
              <RightArrow />
              <ProgressPill status={"COMPLETE"}>
                <DoneAllIcon />
                <Text>Complete</Text>
              </ProgressPill>
              {job.rebateRequired && (
                <>
                  <RightArrow />
                  <ProgressPill status={"REBATE"}>
                    <MonetizationOnOutlinedIcon />
                    <Text>Rebate</Text>
                  </ProgressPill>
                </>
              )}
            </Flex>
          </Flex>
          <ProgressIndicatorText
            jobStatus={
              job.rebateRequired && job.rebateReady && job.status === "COMPLETE"
                ? "REBATE"
                : job.status
            }
          />

          {job.status !== "PICKUP" && (job.totalInventory ?? 0) > 0 && (
            <Flex flexDir="row" gap="8px" mt="12px">
              {/* <ButtonCustom neutral onClick={handleAcknowledgement}>
                <DownloadIcon sx={{ marginRight: "4px" }} /> Acknowledgement
                Report
              </ButtonCustom> */}
              {job.status === "COMPLETE" && (
                <ButtonCustom neutral onClick={handleItad}>
                  <DownloadIcon sx={{ marginRight: "4px" }} /> Destruction
                  Report
                </ButtonCustom>
              )}
            </Flex>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const ProgressPreview = ({ job, isOpen = false }) => {
  return (
    <Flex
      style={isOpen ? { padding: "12px", paddingRight: "26px" } : undefined}
      flexDir="row"
      w="100%"
      pr="26px"
      justifyContent="space-between"
    >
      <Flex
        flexDir={{ base: "column", md: "row" }}
        gap={{ base: "8px", md: "26px" }}
      >
        <Flex flexDir="row" alignItems="center" gap="4px">
          <ChecklistIcon />
          {job.totalInventory}
        </Flex>
        <Flex flexDir="row" alignItems="center" gap="4px">
          <CalendarIcon color="brand.gray.400" />
          {job.startDate}
        </Flex>
        <Flex flexDir="row" alignItems="center" gap="4px">
          <PersonIcon style={{ color: "#8f8d88" }} />
          {job.contactEmail}
        </Flex>
      </Flex>

      <Badge
        hidden={isOpen}
        w="fit-content"
        borderRadius="36px"
        fontSize="14px"
        h="fit-content"
        colorScheme={
          job.status === "COMPLETE"
            ? "green"
            : job.status === "PENDING"
            ? "red"
            : "orange"
        }
      >
        {job.status === "PICKUP" ? "PICK-UP REQUESTED" : JobStatus[job.status]}
      </Badge>
    </Flex>
  );
};

const RightArrow = () => {
  return (
    <Image src="/assets/dashboard/icons/progress-right.png" w="24px" h="24px" />
  );
};

const ProgressIndicatorText = ({ jobStatus }) => {
  const FlexCustom = ({ status, children }) => {
    return (
      <Flex
        display={status !== jobStatus ? "none" : undefined}
        flexDir="row"
        gap="4px"
      >
        {children}
      </Flex>
    );
  };

  return (
    <Flex color="brand.gray.400" flexDir="row" justifyContent="space-between">
      <FlexCustom status={"PICKUP"}>
        <Text>
          We are currently reviewing your pick-up request. Check back for
          updates.
        </Text>
      </FlexCustom>
      <FlexCustom status={"PENDING"}>
        <Text>
          The job is currently waiting to be processed. Check back for real-time
          updates!
        </Text>
      </FlexCustom>
      <FlexCustom status={"COLLECTION"}>
        <Text>
          We have collected your items are preparing them for processing.
        </Text>
      </FlexCustom>
      <FlexCustom status={"IN_PROGRESS"}>
        <Text>
          We are currently processing your recycled goods. Not long now!
        </Text>
      </FlexCustom>
      <FlexCustom status={"COMPLETE"}>
        <Text>
          This job is completed! Your ITAD and Erasure reports will be
          dispatched.
        </Text>
      </FlexCustom>
      <FlexCustom status={"REBATE"}>
        <Text>
          This job is Ready for Rebate. Your rebate will be processed.
        </Text>
      </FlexCustom>
    </Flex>
  );
};
