import { DropoffModel } from "../../models/dropoff.model";
import { BaseService } from "../base.service";

export class DropoffService extends BaseService {
  constructor() {
    super();
  }

  async post(data: any) {
    return await this.client.post(`/company/dropoff`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async getList(): Promise<DropoffModel[]> {
    const resp = await this.client.get(`/company/dropoff/list`);
    return resp.data;
  }

  async downloadFile(data: any): Promise<any> {
    const resp = await this.client.post(
      `/company/dropoff/media/download`,
      data
    );
    return resp.data;
  }

  async delete(data: any) {
    const resp = await this.client.put(`/company/dropoff/delete`, data);
    return resp.data;
  }

  async exportToCsv(filter?: any): Promise<any> {
    const resp = await this.client.post(
      `/company/dropoff/list/export`,
      filter,
      {
        responseType: "blob",
      }
    );
    return resp.data;
  }
}
