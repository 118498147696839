import { Box, Container, Text, Image, useToast, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { useCallback } from "react";

import {
  DropEvent,
  DropzoneOptions,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import { ButtonCustom } from "./form.button";
import { ellipsesText } from "../../../util/string.util";

export interface Dropzone {
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;

  title?: string;
  accept: any;
  maxFiles?: number;
  isUploaded?: boolean;
  filename?: string;
  isInvalid?: boolean;
  invalidInputMessage?: string;
  uploadedLabel?: string;
  additionalText?: string;
  onlyButton?: boolean;
  buttonText?: string;
  backgroundImageUrl?: string;
  allowDelete?: boolean;
  onDelete?: () => void;
  uploadButtonStyle?: {};
  type: "picture" | "document";
}

export const FileUploadInput: React.FC<Dropzone> = (payload: Dropzone) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: payload.onDrop,
    accept: payload.accept,
    maxFiles: payload.maxFiles ? payload.maxFiles : 0,
    multiple: false,
    maxSize: 25000000,
  } as any as DropzoneOptions);

  const {
    isUploaded,
    filename,
    additionalText,
    isInvalid,
    invalidInputMessage,
    onlyButton,
    type,
    title,
    uploadedLabel,
    buttonText,
    backgroundImageUrl,
    allowDelete,
    uploadButtonStyle,
    onDelete,
  } = payload;

  return (
    <>
      {onlyButton ? (
        <UploadButtons
          type={type}
          uploadedLabel={uploadedLabel}
          isUploaded={isUploaded}
          isInvalid={isInvalid}
          open={open}
          invalidInputMessage={invalidInputMessage}
          buttonText={buttonText}
        >
          <input className="dropzone-input" {...getInputProps()} />
        </UploadButtons>
      ) : (
        <>
          <Box fontSize="15px" fontWeight="bold">
            Upload inventory sheet
          </Box>
          <CContainer
            style={
              backgroundImageUrl
                ? {
                    backgroundImage: `url("${backgroundImageUrl}")`,
                    border: 0,
                    backgroundPosition: "center",
                    backgroundSize: "100% auto",
                  }
                : {}
            }
          >
            <Box
              {...getRootProps()}
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Box mb="12px" textAlign="center">
                <input className="dropzone-input" {...getInputProps()} />
                {isDragActive ? (
                  <p className="dropzone-content text-secondary">
                    Release to drop the file here
                  </p>
                ) : (
                  isUploaded ?? (
                    <>
                      <p className="dropzone-content cta-button">
                        Drag and drop here to upload.
                      </p>
                      {!!additionalText ? (
                        <SecondaryText className="dropzone-content">
                          {additionalText}
                        </SecondaryText>
                      ) : (
                        <></>
                      )}
                    </>
                  )
                )}
              </Box>
              <UploadButtons
                style={uploadButtonStyle}
                type={type}
                uploadedLabel={uploadedLabel}
                isUploaded={isUploaded}
                isInvalid={isInvalid}
                open={open}
                invalidInputMessage={invalidInputMessage}
                buttonText={buttonText}
              />
            </Box>

            <ButtonCustom
              onClick={onDelete}
              hidden={!(allowDelete && (isUploaded || backgroundImageUrl))}
              style={{
                marginTop: "12px",
                marginLeft: "8px",
                background: "transparent",
                border: "1px solid white",
                color: "white",
                width: "150px",
              }}
            >
              Delete
            </ButtonCustom>
          </CContainer>
        </>
      )}
    </>
  );
};

const UploadButtons: React.FC<any> = (props: any) => {
  const {
    isUploaded,
    isInvalid,
    open,
    invalidInputMessage,
    type,
    children,
    uploadedLabel,
    buttonText,
    style,
  } = props;

  return (
    <>
      <ButtonCustom
        type="button"
        onClick={open}
        neutral
        style={{ width: "100%", ...style }}
      >
        <Image
          src={`/assets/form/${type}.svg`}
          alt="upload"
          width="22px"
          height="22px"
          mr="6px"
        />
        {isUploaded
          ? !uploadedLabel?.length
            ? "Upload Again"
            : ellipsesText(uploadedLabel, 28)
          : buttonText ?? `Upload ${type}`}
        {children}
      </ButtonCustom>
      {isInvalid === true ? (
        <Text as="span" color="red" fontWeight="bold" mt="20px">
          {invalidInputMessage ?? "You must upload a file."}
        </Text>
      ) : (
        <></>
      )}
    </>
  );
};

const CContainer = styled(Container)({
  border: "1px dashed #D5D3D9",
  padding: "40px",
  width: "100%",
  borderRadius: "4px",
  display: "flex",
  justifyContent: "center",
});

const SecondaryText = styled("p")({
  color: "#49504F",
  fontSize: "14px",
  marginBottom: "16px",
});
