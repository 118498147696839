import {
  Box,
  TableContainer as ChakraTableContainer,
  Table as ChakraTable,
  Flex,
  Image,
} from "@chakra-ui/react";

export const TableContainerMobile = ({
  isEmpty = false,
  emptyTableText = undefined,
  columns,
  data,
  isRow = false,
  ...props
}) => {
  return (
    <Flex flexDir="column" {...props} overflowX="auto" pt="12px">
      {!isEmpty ? (
        <Flex flexDir="column" gap="16px">
          {data.map((row, i) => (
            <Flex
              p="16px"
              border="1px solid #ECECEC"
              style={{
                boxShadow: "0 0 10px 1px #ECECEC",
              }}
              borderRadius="6px"
              flexDir="column"
              gap="6px"
              key={i}
            >
              {columns.map((column, j) => (
                <Box key={j} p={column.accessor === "notes" && 0}>
                  {column.callback ? (
                    <Flex flexDir="row" gap="6px">
                      <Box fontWeight="600">{column.header}</Box>
                      {column.callback(row[column.accessor], row)}
                    </Flex>
                  ) : (
                    <Flex flexDir="row" gap="6px">
                      <Box fontWeight="600">{column.header}</Box>
                      {column.header} {row[column.accessor]}
                    </Flex>
                  )}
                </Box>
              ))}
            </Flex>
          ))}
        </Flex>
      ) : (
        <Flex
          h="40vh"
          flexDir="column"
          justifyContent={"center"}
          alignItems="center"
        >
          <Image
            src={`/assets/table/earth.png`}
            alt="upload"
            width="250px"
            // height="75px"
            mr="6px"
          />
          <Box color="brand.gray.400" fontFamily="zolo-header">
            {emptyTableText ?? "Nothing here... yet."}
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
