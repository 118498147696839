import { UserModel } from "../../models/user.model";
import { BaseService } from "../base.service";

//** Only users with role_id=2 (admin) can access */
export class AdminUserService extends BaseService {
  constructor() {
    super();
  }

  async getCompanyUsers(): Promise<UserModel[]> {
    const resp = await this.client.get(`/admin/user/list`);
    return resp.data;
  }

  async getInvitedList(): Promise<UserModel[]> {
    const resp = await this.client.get(`/admin/user/manage/invite/list`);
    return resp.data;
  }

  async inviteUser(user: { email }): Promise<UserModel> {
    const resp = await this.client.post(`/admin/user/manage/invite`, user);
    return resp.data;
  }

  async revokeInvite(email: string) {
    await this.client.post(`/admin/user/manage/invite/revoke`, {
      email,
    });
  }

  async deactivate(user: { email }): Promise<any[]> {
    const resp = await this.client.post(`/admin/user/manage/deactivate`, user);
    return resp.data;
  }

  async activate(user: { email }): Promise<any[]> {
    const resp = await this.client.post(`/admin/user/manage/activate`, user);
    return resp.data;
  }

  async updateRole({ email, role }): Promise<any[]> {
    const resp = await this.client.put(`/admin/user/manage/role`, {
      email,
      role,
    });
    return resp.data;
  }
}
