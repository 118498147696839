import { Flex, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CompanyService } from "../../../../service/company/company.service";
import { UserService } from "../../../../service/user/user.service";
import { ButtonCustom } from "../../../form/controls/form.button";
import { Loading } from "../../../shared/Loading";
import { Modal } from "../../Modal";
import { FormInput } from "../../../form/controls/form.input";
import { AdminCompanyService } from "../../../../service/admin/admin-company.service";
import { successToast } from "../../../../constants/toast.constants";

export const ProfileEditModal = ({ isOpen, onClose, onSubmit, profile }) => {
  const companyService = new CompanyService();
  const adminCompanyService = new AdminCompanyService();

  const userService = new UserService();

  const toast = useToast();
  const [formState, setFormState] = useState({} as any);
  const [loading, setLoading] = useState(false);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleClose = () => {
    setFormState({});
    onClose();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    const obj = { ...profile, ...formState };
    const payload = {
      firstName: obj.firstName,
      lastName: obj.lastName,
      mobile: obj.mobile,
    };

    await userService
      .update(payload)
      .then(() => {
        toast(successToast("Company updated"));
        onSubmit(payload);
      })
      .catch(() => {
        toast(successToast("Error updating company"));
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const init = async () => {};

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal isOpen={isOpen} header="Edit details" onClose={handleClose}>
      <Loading loading={loading} fill ignoreDelay />
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <Flex flexDir="row" gap="12px">
            <FormInput
              required
              title="First name"
              name="firstName"
              defaultValue={profile.firstName}
              onChange={onInputChange}
            />
            <FormInput
              required
              name="lastName"
              title="Last name"
              defaultValue={profile.lastName}
              onChange={onInputChange}
            />
          </Flex>
          <FormInput
            name="mobile"
            title="Phone number"
            type="tel"
            defaultValue={profile.mobile}
            onChange={onInputChange}
          />

          <Flex gap="12px" justifyContent="flex-end">
            <ButtonCustom secondary neutral onClick={onClose}>
              Cancel
            </ButtonCustom>
            <ButtonCustom type="submit">Confirm</ButtonCustom>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};
