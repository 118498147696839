export enum InventoryCategory {
  LAPTOP = "Laptop",
  DESKTOP = "Desktop",
  MONITOR = "Monitor",
  PRINTER = "Printer",
  PHONE = "Phone",
  TABLET = "Tablet",
  SERVER = "Server",
  HARD_DRIVE = "Hard Drive",
  MISC = "Misc",
}
