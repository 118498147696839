import {
  Box,
  Button,
  Flex,
  Text,
  Icon,
  Center,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Image } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InboxIcon from "@mui/icons-material/Inbox";
import LogoutIcon from "@mui/icons-material/Logout";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import SecurityIcon from "@mui/icons-material/Security";
import { AvatarCustom } from "../form/avatar.custom";
import { Loading } from "../shared/Loading";
import { ReactComponent as CompostIconSvg } from "../../custom-assets/compost.svg";
import { MagiclinkService } from "../../service/magiclink/magiclink.service";
import { RootState, destroyAllState } from "../../store";
import { ellipsesText } from "../../util/string.util";

const links = [
  { route: "dashboard", name: "Dashboard", icon: DashboardIcon },
  { route: "dropoff", name: "Pick-up Request", icon: InboxIcon },
  { route: "job", name: "GreenGuard™ Tracker", icon: SecurityIcon },
  { route: "branch", name: "Branches", icon: BusinessOutlinedIcon },
];

export const Sidebar: React.FC<any> = (props: any) => {
  const { onToggle, style } = props;

  const magiclinkService = new MagiclinkService();

  const { photoUri } = useSelector((state: RootState) => state.userProfile);

  const { name: companyName, isHeadquarters, rebateRequired } = useSelector(
    (state: RootState) => state.company
  );
  const { credits, monthEmission } = useSelector(
    (state: RootState) => state.dashboard
  );

  const [loading, setLoading] = useState(false);

  const [sidebarWidth, setSidebarWidth] = useState(240);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    setSidebarWidth(sidebarOpen ? 80 : 240);
    if (onToggle) onToggle(!sidebarOpen);
  };

  const navigate = useNavigate();

  const logout = async () => {
    setLoading(true);
    await magiclinkService
      .logout()
      .then(() => {
        destroyAllState();
      })
      .finally(() => {
        navigate("/");
        setLoading(false);
      });
  };

  return (
    <Box
      maxWidth={`${sidebarWidth}px`}
      minH="100%"
      position="fixed"
      zIndex={11}
    >
      <Loading loading={loading} fill ignoreDelay />
      <Flex
        minH="100vh"
        bg="brand.green.200"
        borderRadius="8px"
        border="3px solid white"
        w={`${sidebarWidth}px`}
        maxW={`${sidebarWidth}px`}
        py="12px"
        flexDirection="column"
        justifyContent="space-between"
        position="relative"
        pb={{ base: "100px", md: 0 }}
        {...style}
      >
        <Box
          hidden={!onToggle}
          pos="absolute"
          p="4px"
          borderRadius="4px"
          border="1px solid white"
          top="26px"
          right={sidebarOpen ? "-10px" : "-18px"}
          bg="brand.green.100"
          cursor="pointer"
          color="brand.green.200"
          w="24px"
          h="24px"
          fontSize="14px"
          onClick={toggleSidebar}
        >
          {sidebarOpen ? <FaChevronLeft /> : <FaChevronRight />}
        </Box>
        <Box>
          <Flex flexDir="column" gap="6px" alignItems="center">
            <Image
              src="/logo-white.svg"
              alt="Zolo Logo"
              width="120px"
              height="30px"
              mb={6}
              cursor="pointer"
              onClick={() => navigate("/app/dashboard")}
            />
          </Flex>
          <Flex
            mb={sidebarOpen ? "16px" : "26px"}
            flexDir="column"
            alignItems="center"
            gap="6px"
          >
            <Box cursor="pointer" onClick={() => navigate("/app/account")}>
              <AvatarCustom
                src={photoUri}
                widthxheight={sidebarOpen ? "60px" : "40px"}
              />
            </Box>
            <Box hidden={!sidebarOpen} color="white" fontSize="18px">
              {/* {companyName} */}
              {ellipsesText(companyName, 20)}
            </Box>
          </Flex>
          <Center>
            <Flex
              cursor="pointer"
              hidden={!sidebarOpen}
              flexDir="row"
              gap="14px"
              mb="22px"
              fontWeight="700"
              fontSize="14px"
              color="white"
            >
              {rebateRequired && (

                <Tooltip label="Credits" placement="bottom">
                <Flex alignItems="center" borderRadius="4px">
                  <MonetizationOnIcon
                    style={{
                      fontSize: "18px",
                      marginRight: "4px",
                      color: "white",
                    }}
                    />
                  {credits ?? 0}
                </Flex>
              </Tooltip>
                  )}
              <Tooltip label="Emissions saved (month)" placement="bottom">
                <Flex alignItems="center" borderRadius="4px">
                  <Box style={{ fontSize: "18px", marginRight: "4px" }}>
                    <CompostIconSvg fill="white" height={18} width={18} />
                  </Box>
                  {monthEmission ?? 0}
                </Flex>
              </Tooltip>
            </Flex>
          </Center>

          {(isHeadquarters
            ? links
            : links.filter((x) => x.route !== "branch")
          ).map((link, i) => {
            return (
              <LinkStyled
                active={window.location.pathname.startsWith(
                  `/app/${link.route}`
                )}
                key={i}
                onClick={() => navigate(`/app/${link.route}`)}
              >
                <Flex flexDirection="row" gap={1} alignItems={"center"}>
                  <Icon as={link.icon}></Icon>
                  {sidebarOpen && <Text fontSize={"15px"}>{link.name}</Text>}
                </Flex>
              </LinkStyled>
            );
          })}
        </Box>
        <Box>
          <LinkStyled
            active={window.location.pathname.startsWith(`/app/account`)}
            onClick={() => navigate(`/app/account`)}
          >
            <Flex flexDirection="row" gap={1} alignItems={"center"}>
              <Icon as={AccountCircleIcon}></Icon>
              {sidebarOpen && (
                <Text fontSize={"15px"}>
                  {isHeadquarters ? "HQ" : "Branch"} Settings
                </Text>
              )}
            </Flex>
          </LinkStyled>

          <LinkStyled onClick={logout}>
            <Flex flexDirection="row" gap={1} alignItems={"center"}>
              <Icon as={LogoutIcon}></Icon>
              {sidebarOpen && <Text fontSize={"15px"}>Logout</Text>}
            </Flex>
          </LinkStyled>
        </Box>
      </Flex>
    </Box>
  );
};

const LinkStyled: React.FC<any> = (props: any) => {
  const { href, children, active, onClick } = props;

  return (
    <Button
      onClick={onClick}
      style={{ fontFamily: "zolo-text" }}
      boxSize="initial"
      justifyContent="flex-start"
      flexDirection={"row"}
      alignItems="center"
      bg="transparent"
      borderLeftWidth="4px"
      color={active ? "brand.yellow.200" : "white"}
      borderLeftColor={active ? "brand.yellow.200" : "brand.green.200"}
      mb={{
        lg: "10px",
      }}
      mx={{
        xl: "auto",
      }}
      ps={{
        sm: "10px",
        xl: "12px",
      }}
      _hover={{
        color: "brand.yellow.200",
      }}
      py="12px"
      borderRadius="0"
      w="100%"
    >
      {children}
    </Button>
  );
};
