import {
  Box,
  Flex,
  Link,
  Text,
  Image,
  Divider,
  AbsoluteCenter,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MagiclinkService } from "../../service/magiclink/magiclink.service";
import { FormInput } from "../../components/form/controls/form.input";
import { ButtonCustom } from "../../components/form/controls/form.button";
import { RootState, destroyAllState } from "../../store";
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft";

const Register: React.FC<any> = (props: any) => {
  const magiclinkService = new MagiclinkService();

  const navigate = useNavigate();

  const [formState, setFormState] = useState({ email: "" });
  const [loading, setLoading] = useState(false);

  const sessionToken = useSelector(
    (state: RootState) => state.token.accessToken
  );

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);
    await magiclinkService
      .handleLoginWithEmail(formState.email, false)
      .then(() => {
        // Open empty page and close
        window.open("about:blank", "_self");
        window.close();
      })
      .catch((err) => {
        alert(err);
        return;
      })
      .finally(() => {
        setLoading(true);
      });
  };

  const handleSocialRegister = async (provider: "google" | "microsoft") => {
    await magiclinkService.handleLoginWithSocial(provider, false);
  };

  const init = async () => {
    if (!!sessionToken?.length) {
      navigate("/dashboard");
      return;
    }

    await magiclinkService.logout();
    destroyAllState();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Flex
      h={"100vh"}
      w="100vw"
      bg={{ base: "brand.green.200", md: "#F8F9FC" }}
      justifyContent="center"
      alignItems="center"
      flexDir="column"
    >
      <Flex flexDir={{ base: "column", md: "row" }} h="100%">
        <Box
          pos="relative"
          overflowY="hidden"
          display={{ base: "none", md: "block" }}
          left={0}
          h="100vh"
          w="60vw"
          bg="brand.green.200"
        >
          <Box px="5vw" pos="absolute" bottom="-120px">
            <Image w="100%" src="/assets/homepage-app.svg" />
          </Box>
        </Box>

        <Flex
          w={{ base: "100%", md: "40vw" }}
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          gap="16px"
          h="100%"
        >
          <Flex
            id="test"
            w={{ base: "300px", md: "35vw" }}
            maxW="350px"
            flexDir="column"
            gap="18px"
          >
            <Text
              textAlign="start"
              fontSize={{ base: "36px", md: "46px" }}
              fontWeight="1000px"
              color={{ base: "white", md: "brand.green.200" }}
              style={{ fontFamily: "zolo-header" }}
            >
              Join us.
            </Text>

            <form onSubmit={onSubmit}>
              <Flex
                color={{ base: "white", md: "black" }}
                flexDirection="column"
                gap="16px"
              >
                <FormInput
                  name="email"
                  title="Email"
                  required
                  type="email"
                  placeholder="Enter your email address"
                  onChange={onInputChange}
                />
                <ButtonCustom type="submit">
                  {!loading ? "Login to ZOLO" : "Waiting for confirmation..."}
                </ButtonCustom>
              </Flex>
            </form>

            <Box position="relative" my="15px">
              <Divider />
              <AbsoluteCenter px="4">
                <Flex
                  my="6px"
                  flexDir="column"
                  textAlign="center"
                  justifyContent="center"
                  fontSize="14px"
                  gap="2px"
                >
                  <Box fontWeight="700" color="gray">
                    OR
                  </Box>
                </Flex>
              </AbsoluteCenter>
            </Box>

            <Center>
              <Flex flexDir="column" gap="12px">
                {/* <ButtonCustom
                  style={{ width: "250px" }}
                  neutral
                  onClick={() => handleSocialRegister("google")}
                >
                  <Flex
                    flexDir="row"
                    justifyContent="space-between"
                    alignItems="center"
                    px="10px"
                  >
                    <Image
                      src="/assets/icons/google.svg"
                      style={{
                        width: "18px",
                        marginRight: "4px",
                      }}
                    />
                    Join with Google
                    <Box w="1px"></Box>
                  </Flex>
                </ButtonCustom> */}
                <ButtonCustom
                  style={{ width: "250px" }}
                  neutral
                  onClick={() => handleSocialRegister("microsoft")}
                >
                  <Flex
                    flexDir="row"
                    justifyContent="space-between"
                    alignItems="center"
                    px="10px"
                  >
                    <Image
                      src="/assets/icons/microsoft.svg"
                      style={{
                        width: "14px",
                        marginRight: "4px",
                      }}
                    />
                    Join with Microsoft
                    <Box w="1px"></Box>
                  </Flex>
                </ButtonCustom>
              </Flex>
            </Center>

            <Text
              color={{ base: "white", md: "black" }}
              hidden={loading}
              textAlign="center"
              fontSize="14px"
            >
              Already have an account?{" "}
              <Link
                href="/app/auth/login"
                color={{ base: "brand.yellow.200", md: "brand.green.200" }}
                fontWeight="700"
              >
                Log in
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Register;
