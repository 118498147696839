import { Box, Flex } from "@chakra-ui/react";
import { ButtonCustom } from "../../../form/controls/form.button";
import { Modal } from "../../Modal";
import { useNavigate } from "react-router-dom";

export const ProfileIncompleteModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} header="Profile incomplete" onClose={onClose}>
      <Flex flexDir="column" gap="12px">
        <Box>
          We just need a few more details from you before you can make your
          first pick-up request.
        </Box>
        <Box></Box>
        <Flex gap="12px" justifyContent="flex-end">
          <ButtonCustom secondary neutral onClick={onClose}>
            Later
          </ButtonCustom>
          <ButtonCustom onClick={() => navigate(`/app/account`)}>
            Go to settings
          </ButtonCustom>
        </Flex>
      </Flex>
    </Modal>
  );
};
