import { useEffect, useState } from "react";
import { AdminUserService } from "../../../service/admin/admin-user.service";
import { Flex, ModalFooter, useToast } from "@chakra-ui/react";
import { Modal } from "../Modal";
import { Loading } from "../../shared/Loading";
import { ButtonCustom } from "../../form/controls/form.button";
import { FormInput } from "../../form/controls/form.input";
import { errorToast, successToast } from "../../../constants/toast.constants";

export const InviteUserModal = ({ isOpen, onClose, onSubmit }) => {
  const adminUserService = new AdminUserService();
  const toast = useToast();

  const [formState, setFormState] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    await adminUserService
      .inviteUser(formState)
      .then(() => {
        setLoading(false);
        onClose();
        toast(
          successToast(`${formState.email} has been successfully invited!`)
        );
      })
      .catch((err) => {
        console.log(err);
        toast(errorToast(`Something went wrong. Please try again.`));
      })
      .finally(() => {
        setLoading(false);
        onSubmit();
      });
  };

  const handleClose = () => {
    setFormState({
      email: "",
    });
    onClose();
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Modal isOpen={isOpen} header="Invite User" onClose={handleClose}>
      <Loading loading={loading} fill ignoreDelay />
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <FormInput
            name="email"
            title="User Email"
            required
            type="email"
            placeholder="Enter user email address"
            onChange={onInputChange}
          />

          <Flex gap="12px" justifyContent="flex-end">
            <ButtonCustom secondary neutral onClick={onClose}>
              Cancel
            </ButtonCustom>
            <ButtonCustom type="submit">Invite</ButtonCustom>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};
