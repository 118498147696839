import { Box, Center, Flex, Link, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Image } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IdentityService } from "../../service/identity/identity.service";
import { MagiclinkService } from "../../service/magiclink/magiclink.service";
import AuthLayout from "../../components/layout/basic.layout";
import BaseContainer from "../../components/form/base.container";
import { FormInput } from "../../components/form/controls/form.input";
import { ButtonCustom } from "../../components/form/controls/form.button";
import { RootState, destroyAllState } from "../../store";
import { getQueryParam } from "../../util/url.util";
import { Loading } from "../../components/shared/Loading";
import { UserService } from "../../service/user/user.service";
import { update } from "../../store/slice/Account/user-profile.slice";
import { update as updateToken } from "../../store/slice/Token/token.slice";
import { update as updateCompany } from "../../store/slice/Account/company.slice";

import TokenService from "../../service/identity/token.service";
import { ForestBackground } from "../../components/shared/ForestBackground";
import { errorToast } from "../../constants/toast.constants";

const RegisterUser: React.FC<any> = (props: any) => {
  const magiclinkService = new MagiclinkService();
  const identityService = new IdentityService();
  const userService = new UserService();
  const tokenService = new TokenService();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const [formState, setFormState] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });

  const [loading, setLoading] = useState(false);

  const { regToken } = useParams();

  const [didToken, setDidToken] = useState("");

  const sessionToken = useSelector(
    (state: RootState) => state.token.accessToken
  );

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);
    await identityService
      .register(formState.email, didToken)
      .then(async () => {
        await registerUser();
      })
      .catch(async (err) => {
        console.log(`Error registering identity. Err: ${err?.message}`);
        navigate("/app/auth/login");
      })
      .finally(() => setLoading(false));
  };

  const registerUser = async () => {
    await userService
      .register({ ...formState, regToken }, didToken)
      .then(async (userProfile) => {
        dispatch(update(userProfile));

        const accessToken = await tokenService
          .getAccessToken(didToken!)
          .catch((error) => {
            navigate("/app/auth/login");
          });

        dispatch(updateToken({ accessToken } as any));
        dispatch(
          updateCompany({
            country: userProfile.company.country,
            name: userProfile.company.name,
            id: userProfile.company.id,
            isHeadquarters: userProfile.company.isHeadquarters,
          })
        );

        navigate("/app/dashboard");
      })
      .catch(() => {
        console.log("Error registering user. Please try again later.");
        toast(errorToast("Error registering user. Please try again later."));
        navigate("/app/auth/login");
      })
      .finally(() => setLoading(false));
  };

  const init = async () => {
    if (!!sessionToken?.length) {
      navigate("/dashboard");
      return;
    }

    const magicCredential = getQueryParam("magic_credential");
    const isSocialLogin = getQueryParam("social_login");

    if (!magicCredential) navigate("/app/auth/login");

    setLoading(true);
    if (isSocialLogin) {
      magiclinkService.magicSocial?.oauth
        .getRedirectResult()
        .addListener("done", async (auth) => {
          const didToken = auth.magic.idToken;
          setDidToken(didToken);
          const email = await magiclinkService.userEmail();
          setFormState({ ...formState, email });
        })
        .addListener("settled", () => setLoading(false))
        .finally(() => setLoading(false));

      return;
    }

    magiclinkService.magic?.auth
      .loginWithCredential()
      .addListener("done", async (didToken) => {
        setDidToken(didToken);
        const email = await magiclinkService.userEmail();
        setFormState({ ...formState, email });
      })
      .addListener("settled", () => setLoading(false))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (didToken) setLoading(false);
  }, [didToken]);

  return (
    <>
      <Loading loading={loading} fill ignoreDelay />
      <AuthLayout>
        <ForestBackground />
        <Flex
          flexDirection="column"
          gap="16px"
          width={{ base: "360px", md: "460px" }}
          alignItems="center"
          zIndex={10}
        >
          <BaseContainer
            styles={{
              marginBottom: 0,
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.5)", // adjust color and transparency here
              backdropFilter: "blur(10px)", // adjust blur radius here
            }}
          >
            <Center mb="40px">
              <Image src="/logo.svg" alt="ZOLO Logo" width="110px" />
            </Center>

            <form onSubmit={onSubmit}>
              <Flex flexDirection="column" gap="16px">
                <Flex
                  flexDir={{ base: "column", md: "row" }}
                  justifyContent="space-between"
                  gap="12px"
                >
                  <FormInput
                    name="firstName"
                    title="First Name"
                    required
                    onChange={onInputChange}
                    placeholder="John"
                  />
                  <FormInput
                    name="lastName"
                    title="Last Name"
                    required
                    onChange={onInputChange}
                    placeholder="Doe"
                  />
                </Flex>
                <FormInput
                  name="email"
                  title="Email"
                  required
                  type="email"
                  defaultValue={formState.email}
                  disabled
                  placeholder="Enter your email address"
                />
                <ButtonCustom disabled={loading} type="submit">
                  Complete registration
                </ButtonCustom>
              </Flex>
            </form>
          </BaseContainer>
        </Flex>
      </AuthLayout>
    </>
  );
};

export default RegisterUser;
