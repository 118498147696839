import { Flex } from "@chakra-ui/react";

export const CardCustom = ({ children, ...props }) => {
  return (
    <Flex
      p="40px"
      w="100%"
      bg="white"
      boxShadow="base"
      borderRadius="md"
      flexDir="column"
      {...props}
    >
      {children}
    </Flex>
  );
};
