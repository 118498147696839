import { Flex, Box, Image } from "@chakra-ui/react";

export const EmptyContent = ({
  title = undefined,
  description = undefined,
  children = undefined,
  ...props
}) => {
  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      p="24px"
      gap="24px"
      borderRadius="16px"
      {...props}
    >
      <Image src="/assets/dashboard/icons/recycle.svg" w="140px" h="140px" />
      <Box fontSize="24px" fontWeight="bold" color="brand.green.300">
        {title}
      </Box>
      <Box color="brand.green.300">{description}</Box>
      {children}
    </Flex>
  );
};
