import { Box } from "@chakra-ui/react";
import { getDayPeriod } from "../../util/date.util";
import { useEffect, useState } from "react";

export const ForestBackground = () => {
  const [fireflies, setFireflies] = useState([]);

  useEffect(() => {
    setFireflies(
      generateFireflies(40, getDayPeriod() === "evening" ? true : false)
    );
  }, []);

  const keyframesFly = `
    @keyframes fly {
      0% { transform: translateY(0px); }
      50% { transform: translateY(50px); }
      100% { transform: translateY(0px); }
    }
  `;

  const keyframesBlink = `
    @keyframes blink {
      0%, 100% { opacity: 0; }
      50% { opacity: 1; }
    }
  `;

  return (
    <Box
      pos="fixed"
      bgImage={
        getDayPeriod() === "evening"
          ? "url('/assets/dashboard/background-dark.svg')"
          : "url('/assets/dashboard/background.svg')"
      }
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      height="100vh"
      width="100vw"
      top="0"
      left="0"
    >
      <style>{keyframesFly}</style>
      <style>{keyframesBlink}</style>
      {fireflies}{" "}
    </Box>
  );
};

const generateFireflies = (count, isDark) => {
  return Array(count)
    .fill(0)
    .map((_, i) => (
      <Box
        key={i}
        background={isDark ? "brand.yellow.300" : "#EFFAC6"}
        style={
          {
            position: "absolute",
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            animation: `fly 10s ease-in-out infinite, blink 2s ease-in-out ${
              Math.random() * 2
            }s infinite`,
            animationDelay: `${Math.random() * 2}s`,
            top: `${Math.random() * 100}vh`,
            left: `${Math.random() * 100}vw`,
            filter: `blur(2px) brightness(200%)`,
            ":before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: "-10px",
              left: "-10px",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              background:
                "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
            },
          } as any
        }
      />
    ));
};
