import { Flex, SimpleGrid, useToast } from "@chakra-ui/react";
import { Modal } from "../Modal";
import { ButtonCustom } from "../../form/controls/form.button";
import { FormInput } from "../../form/controls/form.input";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CompanyService } from "../../../service/company/company.service";
import { errorToast, successToast } from "../../../constants/toast.constants";
import GooglePlacesDropdown from "../../form/controls/google-places-dropdown.input";
import { BranchService } from "../../../service/company/branch.service";

export const AddBranchModal = ({ isOpen, onClose, onSubmit }) => {
  const companyService = new CompanyService();
  const branchService = new BranchService();

  const [formState, setFormState] = useState({} as any);
  const [addressState, setAddressState] = useState({} as any);

  const [loading, setLoading] = useState(false);
  const [addressDirty, setAddressDirty] = useState(false);
  const [googlePlaces, setGooglePlaces] = useState({
    text: "",
    isValid: false,
  } as { text: string; isValid: boolean });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const onGooglePlacesTextChange = (e: any) => {
    const text = e.target.value;
    setAddressDirty(true);

    // Input is invalid until the onPlaceSelected is fired
    setGooglePlaces({ text, isValid: false });
  };

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (name === "address") {
      setGooglePlaces({ text: value, isValid: true });
      setAddressState(value);
      return;
    }

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleClose = () => {
    setFormState({});
    setAddressDirty(false);
    onClose();
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!googlePlaces.isValid && addressDirty) {
      toast(errorToast("Please enter a valid address"));
      return;
    }

    const payload = {
      ...formState,
      address: addressState,
      addressName: addressState.name,
    };

    setLoading(true);
    await branchService
      .createBranch(payload)
      .then(() => {
        toast(successToast("Branch created successfully"));
        onSubmit(payload);
        handleClose();
      })
      .catch(() => {
        toast(errorToast("Failed to create branch"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} header="Add Branch">
      <form onSubmit={handleSubmit}>
        <Flex flexDirection={"column"} gap={"16px"}>
          <FormInput
            name="name"
            title="Branch name"
            placeholder="e.g. Acme Inc"
            onChange={onInputChange}
            required
          />
          <GooglePlacesDropdown
            name="address"
            title="Branch address"
            isInvalid={!googlePlaces.isValid && addressDirty}
            invalidInputMessage="Please enter a valid address"
            onPlaceSelected={onInputChange}
            onChange={onGooglePlacesTextChange}
          />

          {/* <FormInput
            name="url"
            title="Website"
            placeholder="e.g. www.company.com"
            onChange={onInputChange}
            type="url"
          /> */}
          <ButtonCustom
            type="submit"
            style={{ width: "100%", marginTop: "12px" }}
          >
            Add branch
          </ButtonCustom>
        </Flex>
      </form>
    </Modal>
  );
};
