import { Box } from "@chakra-ui/react";
import { ReactComponent as CompostIconSvg } from "./compost.svg";

export const CompostIcon = ({ color }) => {
  return (
    <Box mb="6px">
      <CompostIconSvg fill={color} />
    </Box>
  );
};
