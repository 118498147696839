import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { Box, Divider, Flex, Text, useToast } from "@chakra-ui/react";
import { convertIsoTime } from "../../../util/date.util";
import { AvatarCustom } from "../../form/avatar.custom";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { useEffect, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { ellipsesText } from "../../../util/string.util";

export const JobDetailContent = ({ job, totalInventory }) => {
  return (
    <>
      <Flex fontWeight="500" color="gray" flexDir="column" gap="8px">
        <Flex flexDir="row" gap="4px">
          <BusinessIcon />
          <Text
            cursor="pointer"
            textDecoration="underline"
            onClick={() =>
              window.open(
                `/app/company/${job.companyId}`,
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            {job.companyName}
          </Text>
        </Flex>
        <Flex flexDir="row" gap="4px">
          <LocationOnOutlinedIcon />
          {job.companyAddressName}
        </Flex>

        <Flex flexDir="row" gap="4px">
          <ChecklistIcon />
          {totalInventory}
        </Flex>
      </Flex>

      <Divider my={4} />

      <Flex flexDir="row" justifyContent="space-between">
        <Flex flexDir="column" gap="16px" maxW="500px">
          <Flex flexDir="row" gap="100px" mb={4}>
            <CardDetail
              label="Request Date"
              value={convertIsoTime(job.startDate)}
              icon={CalendarMonthOutlinedIcon}
            />
            <PhotoCard src={job.contactPhotoUri} title="Main Contact">
              {job.contactEmail ?? "-"}
            </PhotoCard>
          </Flex>

          <Flex flexDir="row" gap="100px" mb={4}>
            <CardDetail
              label="Created Date"
              value={convertIsoTime(job.createdDate)}
              icon={CalendarMonthOutlinedIcon}
            />
            {job.assignedAdminPhotoUri && (
              <PhotoCard src={job.assignedAdminPhotoUri} title="ZOLO Champion">
                {job.assignedAdminEmail}
              </PhotoCard>
            )}
          </Flex>

          {!!job.notes && (
            <Box mb="16px">
              <CardDetail
                label="Notes"
                value={job.notes}
                icon={CommentOutlinedIcon}
              />
            </Box>
          )}
        </Flex>

        <AttachmentSection job={job} />
      </Flex>
    </>
  );
};

const CardDetail = ({ label, value, icon = undefined, ...props }) => {
  return (
    <Flex fontSize="14px" flexDir="column" gap="6px" {...props}>
      <Box fontWeight="500" color="gray">
        {label}
      </Box>
      <Box>{value}</Box>
    </Flex>
  );
};

const PhotoCard = ({ src, title, children }) => {
  return (
    <Flex flexDir="row" gap="12px" alignItems="center">
      <AvatarCustom border="3px solid white" src={src} widthxheight="50px" />
      <Box fontSize="14px">
        <Box fontWeight="500" color="gray">
          {title}
        </Box>
        <Box>{children}</Box>
      </Box>
    </Flex>
  );
};

const AttachmentSection = ({ job }) => {
  const [attachments, setAttachments] = useState(job.attachments ?? []);

  useEffect(() => {
    setAttachments(job.attachments);
  }, [job.attachments]);

  return (
    <>
      <Flex
        flexDir="column"
        textAlign="right"
        alignItems="flex-end"
        maxW="400px"
      >
        <Flex fontWeight="500" fontSize="14px" mb={2}>
          Attachments - ({attachments?.length ?? 0}){" "}
          <AttachFileIcon style={{ width: "16px" }} />
        </Flex>

        {attachments?.map((attachment, index) => (
          <Flex
            key={index}
            cursor="pointer"
            fontWeight="500"
            alignItems="center"
            gap={1}
          >
            <Box
              fontSize="10px"
              fontWeight="500"
              color="white"
              bg="gray"
              borderRadius="4px"
              px={2}
              h="fit-content"
            >
              {attachment.mediaContext}
            </Box>

            <Box
              textDecor="underline"
              color="blue"
              fontSize="14px"
              w="160px"
              onClick={() => window.open(attachment.fileUri, "_blank")}
            >
              {ellipsesText(attachment.name, 22)}
            </Box>
          </Flex>
        ))}
      </Flex>
    </>
  );
};
