import { Text, Box, Flex } from "@chakra-ui/react";

export const PageTitle = ({
  fontSize = undefined,
  color = undefined,
  children,
  ...props
}) => {
  return (
    <Flex
      style={{ fontFamily: "zolo-header" }}
      textTransform="uppercase"
      flexDir="column"
      {...props}
    >
      <Text
        color={color ?? "brand.green.200"}
        fontSize={fontSize ?? "3xl"}
        fontWeight="bold"
      >
        {children}
      </Text>
      <Box bg="brand.yellow.300" h="3px" w="40px"></Box>
    </Flex>
  );
};
