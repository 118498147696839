import axios, { AxiosInstance } from "axios";
import config from "../configs/index";
import { MagiclinkService } from "./magiclink/magiclink.service";
import { store } from "../store";
import { tokenSlice, initialState } from "../store/slice/Token/token.slice";
import { destroyAllState } from "../store";

export class BaseService {
  protected client: AxiosInstance;
  protected isPublicRoute: boolean;
  protected magiclinkService: MagiclinkService;

  constructor(baseUrl?: string, isPublicRoute: boolean = false) {
    this.client = axios.create({
      baseURL: baseUrl ?? config.apiUrl,
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
    });
    this.isPublicRoute = isPublicRoute;
    this.magiclinkService = new MagiclinkService();
    this.setupRequestInterceptors();
    this.setupResponseInterceptors();
  }

  protected set setIsPublicRoute(isPublic: boolean) {
    this.isPublicRoute = isPublic;
  }

  protected setupRequestInterceptors() {
    if (this.isPublicRoute) return;
    this.client.interceptors.request.use(async (request) => {
      const token = store.getState().token.accessToken;
      // if does not exist then Auth header should not be set
      if (request.headers) {
        if (!request.headers.Authorization && token) {
          request.headers.Authorization = `Bearer ${token}`;
        }
      }
      return request;
    });
  }

  private setupResponseInterceptors() {
    this.client.interceptors.response.use(undefined, async (error) => {
      const response = error.response;
      if (response) {
        if (response.status === 401) {
          if (error.config && !error.config.__isRetryRequest) {
            error.config.__isRetryRequest = true;
            return this.client(error.config);
          } else {
            // Clear redux inc access token
            destroyAllState();
            await this.magiclinkService.logout();
            const redirectRoute = "/app/auth/login";
            if (window.location.pathname !== redirectRoute)
              window.location.href = redirectRoute;
          }
        }
      }
      return Promise.reject(error);
    });
  }
}
