import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const ProviderWrapper = ({ children }) => {
  const email = useSelector((state: RootState) => state.userProfile.email);

  // Usage: color="brand.green.200" in child components
  const theme = extendTheme({
    colors: {
      brand: {
        green: {
          100: "#457662",
          200: "#265542",
          300: "#20392f",
          400: "#EBF8F3",
        },
        yellow: {
          100: "#f£2c3",
          200: "#ffdf6d",
          300: "#dab94d",
        },
        brown: {
          100: "#e29c56",
          200: "#d28343",
          300: "#b06324",
        },
        skin: {
          100: "#f3ddcb",
          200: "#e5cab3",
          300: "#dab29b",
        },
        gray: {
          100: "#F9F9F8",
          200: "#d4d4cb",
          300: "#b4bZae",
          400: "#8f8d88",
          500: "#666563",
          600: "#202020",
        },
      },
    },
  });

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};
