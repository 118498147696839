import { createSlice } from "@reduxjs/toolkit";

export interface Dashboard {
  carbonCredits: number;
  credits: number;
  monthEmission: number;
}

export const dashboardInitialState: Dashboard = {
  carbonCredits: 0,
  credits: 0,
  monthEmission: 0,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: dashboardInitialState,
  reducers: {
    updateDashboard: (state, params) => {
      const f = params.payload as Dashboard;

      state.carbonCredits = f.carbonCredits;
      state.credits = f.credits;
      state.monthEmission = f.monthEmission;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
