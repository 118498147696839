import "./App.css";
import { Provider, useSelector } from "react-redux";
import { store, persistor, RootState } from "./store";
import { Loading } from "./components/shared/Loading";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import { ProviderWrapper } from "./components/app-registration/provider-wrapper";
import { AuthenticatedRoute } from "./components/app-registration/authenticated-route";
import { RegisterRedirect } from "./views/auth/RegisterRedirect";
import Register from "./views/auth/Register";
import Login from "./views/auth/Login";
import { LoginRedirect } from "./views/auth/LoginRedirect";
import { Account } from "./views/app/Account";
import { Dashboard } from "./views/app/Dashboard";
import { Orders } from "./views/app/Orders";
import { Recycle } from "./views/app/Recycle";
import RegisterCompany from "./views/auth/RegisterCompany";
import RegisterUser from "./views/auth/RegisterUser";
import { Dropoffs } from "./views/app/Dropoffs";
import { Branch } from "./views/app/Branch";
import { BranchDetail } from "./views/app/BranchDetail";
import React from "react";
import { RegisterUserRedirect } from "./views/auth/RegisterUserRedirect";
import { Job } from "./views/app/job/Job";
import { JobDetail } from "./views/app/job/JobDetail";

function App() {
  return (
    <Provider store={store}>
      <ProviderWrapper>
        <AppContextProvider>
          <PersistGate
            loading={<Loading loading={true} />}
            persistor={persistor}
          >
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Navigate to="/app/dashboard" />} />
                <Route path="*" element={<Navigate to="/app/dashboard" />} />
                <Route path="/app/auth/login" element={<Login />} />

                <Route
                  path="/app/auth/login/redirect"
                  element={<LoginRedirect />}
                />

                {/* registration */}
                <Route path="/app/auth/register" element={<Register />} />
                <Route
                  path="/app/auth/user/register/redirect"
                  element={<RegisterUserRedirect />}
                />
                <Route
                  path="/app/auth/user/register/:regToken"
                  element={<RegisterUser />}
                />
                <Route
                  path="/app/auth/company/register"
                  element={<RegisterCompany />}
                />
                <Route
                  path="/app/auth/register/redirect"
                  element={<RegisterRedirect />}
                />

                {/* app */}
                <Route path="/app" element={<AuthenticatedRoute />}>
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="dropoff" element={<Dropoffs />} />
                  <Route path="job" element={<Job />} />
                  <Route path="job/:id" element={<JobDetail />} />
                  <Route path="orders" element={<Orders />} />
                  <Route path="recycle" element={<Recycle />} />
                  <Route path="account" element={<Account />} />
                  <Route path="/app/branch" element={<Branch />} />
                  <Route path="/app/branch/:id" element={<BranchDetail />} />
                </Route>

                {/* Authenticated route example */}
                <Route
                  path="/example/authenticated"
                  element={<AuthenticatedRoute />}
                >
                  <Route
                    path="/example/authenticated"
                    element={<>This is authenticated page.</>}
                  />
                </Route>

                {/* Unauthenticated redirect */}
                <Route
                  path="/unauthorized"
                  element={<>Redirected since not authenticated.</>}
                />
              </Routes>
            </BrowserRouter>
          </PersistGate>
        </AppContextProvider>
      </ProviderWrapper>
    </Provider>
  );
}

const AppContextProvider = ({ children }) => {
  const { country } = useSelector((state: RootState) => state.company);

  const context = {
    currencySymbol:
      country === "Philippines" ? "₱" : country === "Australia" ? "$" : "¥",
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

export const AppContext = React.createContext(
  {} as {
    currencySymbol: string;
  }
);

export default App;
