export enum DropoffSource {
  AZURE = "azure",
  JAMF = "jamf",
  KANDJI = "kandji",
  OTHER = "other",
}

export enum DropoffStatus {
  PROCESSING = "processing",
  DELAYED = "delayed",
  PROCESSED = "processed",
}
