import {
  useDisclosure,
  useToast,
  Flex,
  Badge,
  Box,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import {
  successToast,
  errorToast,
} from "../../../../constants/toast.constants";
import { UserService } from "../../../../service/user/user.service";
import { RootState } from "../../../../store";
import { update as updateProfile } from "../../../../store/slice/Account/user-profile.slice";
import { AvatarCustom } from "../../../form/avatar.custom";
import { CardCustom } from "../../../shared/CardCustom";
import { useEffect, useState } from "react";
import { ProfileEditModal } from "./profile-edit.modal";
import { CustomCompanyCard } from "../company/AccountCompanyCard";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

export const CustomProfileCard = () => {
  const userService = new UserService();

  const profile = useSelector((state: RootState) => state.userProfile);
  const { email, displayName, photoUri, mobile, role } = profile;

  const profileModal = useDisclosure();
  const toast = useToast();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [complete, setComplete] = useState(true);

  const handlePhotoChange = async ({ formData, photoUri }) => {
    await userService
      .updateProfilePhoto(formData)
      .then((res) => {
        dispatch(updateProfile({ ...profile, photoUri }));
        toast(successToast("Profile photo updated"));
      })
      .catch((err) => {
        toast(errorToast("Error updating profile photo"));
      });
  };

  const handleProfileUpdate = async (data: any) => {
    dispatch(
      updateProfile({
        ...profile,
        mobile: data.mobile,
        firstName: data.firstName,
        lastName: data.lastName,
        displayName: `${data.firstName} ${data.lastName}`,
      })
    );

    setComplete(true);
  };

  const init = async () => {
    await userService.profileComplete().then((res) => {
      setComplete(res.profileComplete);
    });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <CardCustom
      pt={{ base: "130px", md: "180px" }}
      px={{ base: "16px", md: "40px" }}
    >
      <Flex
        pos="relative"
        mb="46px"
        flexDir="column"
        justifyContent="space-between"
      >
        <AvatarCustom
          allowUpload
          src={photoUri}
          widthxheight="100px"
          styles={{ position: "absolute", top: "-110px" }}
          border="4px solid white"
          onUpload={handlePhotoChange}
        />

        <Flex
          pos="absolute"
          top="-40px"
          right="0"
          flexDir="row"
          justifyContent="flex-end"
          w="fit-content"
        >
          <Flex flexDir="row" gap="4px">
            {!complete && (
              <Tooltip
                label="Please complete your profile"
                aria-label="A tooltip"
              >
                <ErrorOutlineOutlinedIcon
                  style={{ color: "red", fontSize: "28px" }}
                />
              </Tooltip>
            )}
            <Button
              onClick={() => setIsOpen(true)}
              sx={{
                fontSize: "14px",
                padding: "5px",
                width: "fit-content",
                height: "fit-content",
                backgroundColor: "transparent",
                border: "1px solid lightGray",
              }}
            >
              Edit Profile
            </Button>
          </Flex>
        </Flex>

        <Flex flexDir="row" justifyContent="space-between" w="100%">
          <Box fontSize="24px" style={{ fontFamily: "zolo-header" }}>
            {displayName}
          </Box>
          <Flex flexDir="row" gap="3px" h="fit-content">
            <Badge
              sx={{
                backgroundColor: "transparent",
                border: "1px solid",
                borderColor: "lightGray",
              }}
              colorScheme="gray"
              w="fit-content"
            >
              {role}
            </Badge>
            <Badge colorScheme="green" w="fit-content">
              Active
            </Badge>
          </Flex>
        </Flex>
        <Flex
          hidden={!email}
          flexDir="row"
          alignItems="center"
          gap="4px"
          color="brand.gray.400"
          fontWeight="500"
        >
          <MailOutlineIcon fontSize="small" />
          {email}
        </Flex>
        <Flex
          hidden={!mobile}
          flexDir="row"
          alignItems="center"
          gap="4px"
          color="brand.gray.400"
          fontWeight="500"
        >
          <PhoneIcon fontSize="small" />
          {mobile}
        </Flex>
      </Flex>

      <CustomCompanyCard />

      <ProfileEditModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        profile={profile}
        onSubmit={handleProfileUpdate}
      />
    </CardCustom>
  );
};
