import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IdentityService } from "../../service/identity/identity.service";
import { MagiclinkService } from "../../service/magiclink/magiclink.service";
import { RootState, destroyAllState } from "../../store";
import { getQueryParam } from "../../util/url.util";
import { Loading } from "../../components/shared/Loading";
import { UserService } from "../../service/user/user.service";

import TokenService from "../../service/identity/token.service";
import { ForestBackground } from "../../components/shared/ForestBackground";
import {
  AbsoluteCenter,
  Box,
  Divider,
  Flex,
  Image,
  Spinner,
} from "@chakra-ui/react";
import BasicLayout from "../../components/layout/basic.layout";
import BaseContainer from "../../components/form/base.container";
import { ButtonCustom } from "../../components/form/controls/form.button";

export const RegisterUserRedirect: React.FC<any> = (props: any) => {
  const magiclinkService = new MagiclinkService();
  const identityService = new IdentityService();
  const userService = new UserService();
  const tokenService = new TokenService();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });

  const [tokenValid, setTokenValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const token = getQueryParam("token");
  const email = getQueryParam("email");

  const sessionToken = useSelector(
    (state: RootState) => state.token.accessToken
  );

  const init = async () => {
    setLoading(true);
    if (!!sessionToken?.length) {
      navigate("/dashboard");
      return;
    }

    await magiclinkService.logout();
    destroyAllState();

    if (!token || !email) {
      alert("No token or email param provided in email. Redirecting.");
      navigate("/app/auth/login");
      return;
    }

    await userService
      .verifyRegistrationToken(email, token)
      .then(async () => {
        setTokenValid(true);
      })
      .catch(() => {
        alert("Error validating token. Redirecting.");
        navigate("/app/auth/login");
        return;
      })
      .finally(() => setLoading(false));
  };

  const handleEmailVerification = async () => {
    await magiclinkService
      .handleUserRegisterWithEmail(email, token)
      .catch((err) => {
        alert(err);
        return;
      });
  };

  const handleSocialVerification = async (provider: "google" | "microsoft") => {
    await magiclinkService.handleUserRegisterWithSocial(provider, token);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <BasicLayout>
      <ForestBackground />
      {!tokenValid && (
        <StepBox title="Initial security verification">
          <Flex flexDir="column" gap="4px" alignItems="center">
            <Box>Verifying your info...</Box>
            <Spinner
              top="50%"
              thickness="4px"
              speed="0.65s"
              color="brand.yellow.300"
              size="md"
            />
          </Flex>
        </StepBox>
      )}
      {tokenValid && !emailValid && (
        <StepBox title={`Confirm your email`}>
          <Box mb="16px">
            We need to confirm that the email{" "}
            <p>
              <strong>{email}</strong>
            </p>{" "}
            belongs to you.
          </Box>
          <Flex flexDir="column" gap="4px">
            <Box>I want to verify by receiving an email</Box>
            <ButtonCustom type="submit" onClick={handleEmailVerification}>
              Get email
            </ButtonCustom>
          </Flex>

          <Box position="relative" my="25px">
            <Divider />
            <AbsoluteCenter px="4">
              <Flex
                my="6px"
                flexDir="column"
                textAlign="center"
                justifyContent="center"
                fontSize="14px"
                gap="2px"
              >
                <Box fontWeight="700" color="gray">
                  OR
                </Box>
              </Flex>
            </AbsoluteCenter>
          </Box>

          <Flex flexDir="column" gap="4px" alignItems="center">
            <Box>Alternate verification</Box>
            <ButtonCustom
              style={{ width: "250px" }}
              neutral
              onClick={() => handleSocialVerification("microsoft")}
            >
              <Flex
                flexDir="row"
                justifyContent="space-between"
                alignItems="center"
                px="10px"
              >
                <Image
                  src="/assets/icons/microsoft.svg"
                  style={{
                    width: "14px",
                    marginRight: "4px",
                  }}
                />
                Verify with Microsoft
                <Box w="1px"></Box>
              </Flex>
            </ButtonCustom>
          </Flex>
        </StepBox>
      )}
    </BasicLayout>
  );
};

const StepBox = ({ title, children, complete = undefined }) => {
  return (
    <BaseContainer styles={{ maxWidth: "450px", textAlign: "center" }}>
      <Flex flexDir="column" gap="16px" alignItems="center">
        <Flex fontSize="22px" fontWeight="bold">
          {title}
        </Flex>
        <Box>{children}</Box>
      </Flex>
    </BaseContainer>
  );
};
