import React, { useEffect } from "react";
import styles from "../../styles/Home.module.css";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { Sidebar } from "../app/Sidebar";
import Header from "../app/header";
import { SidebarMobile } from "../app/mobile/SidebarMobile";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

const AppLayout: React.FC<any> = (props: any) => {
  const { title, metaAttrs, children } = props;

  const [sidebarWidth, setSidebarWidth] = React.useState(240);

  const handleToggle = (open: boolean) => {
    setSidebarWidth(open ? 240 : 80);
  };

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  });

  return (
    <Box bg="white">
      {!isMobile ? <Sidebar onToggle={handleToggle} /> : <SidebarMobile />}
      <Header leftOffset={sidebarWidth} />
      <Box pl={{ base: 0, lg: `${sidebarWidth}px` }}>
        <main id="main" className={styles.main}>
          {children}
          {/* <SidebarMobile /> */}
        </main>
      </Box>
    </Box>
  );
};

export default AppLayout;
