export const hasNonNullProperty = (obj: any) => {
  const keys = Object.keys(obj);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (obj[key] !== null && obj[key] !== undefined) {
      return true;
    }
  }

  return false;
};

export function transformArrayToObject(arr) {
  let obj = {};
  if (!arr?.length) return obj;

  arr.forEach((item) => {
    let key = item.toUpperCase().replace(/ /g, "_");
    obj[key] = item;
  });
  return obj;
}
