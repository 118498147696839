import { BaseService } from "../base.service";

export class BlanccoService extends BaseService {
  constructor() {
    super();
  }

  async getReportPdf(blanccoReportId) {
    const resp = await this.client.post(
      "/company/integration/blancco/report/download",
      {
        blanccoReportId,
      }
    );

    return resp?.data;
  }
}

export const blanccoService = new BlanccoService();
