import { Text } from "@chakra-ui/react";

export const FormTitle = ({
  title,
  required,
}: {
  title: string;
  required?: boolean;
}) => {
  return (
    <Text fontWeight={600} mb="8px" fontSize={"14px"}>
      {title}
      {required ? (
        <Text
          as={"span"}
          fontWeight="700"
          fontSize="14px"
          color="#792551"
          ml="3px"
        >
          *
        </Text>
      ) : (
        <></>
      )}
    </Text>
  );
};
