import { Flex, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CompanyService } from "../../../../service/company/company.service";
import { UserService } from "../../../../service/user/user.service";
import { ButtonCustom } from "../../../form/controls/form.button";
import { Loading } from "../../../shared/Loading";
import { Modal } from "../../Modal";
import { FormInput } from "../../../form/controls/form.input";
import { AdminCompanyService } from "../../../../service/admin/admin-company.service";
import {
  errorToast,
  successToast,
} from "../../../../constants/toast.constants";
import GooglePlacesDropdown from "../../../form/controls/google-places-dropdown.input";
import { CountryDropdownInput } from "../../../form/controls/country-dropdown.input";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../../../store/slice/Account/company.slice";

export const CompanyEditModal = ({ isOpen, onClose, onSubmit, company }) => {
  const companyService = new CompanyService();
  const adminCompanyService = new AdminCompanyService();

  const companyState = useSelector((state: any) => state.company);

  const dispatch = useDispatch();
  const toast = useToast();

  const [formState, setFormState] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [addressDirty, setAddressDirty] = useState(false);
  const [googlePlaces, setGooglePlaces] = useState({
    text: company.address?.name,
    isValid: !!company.address?.name?.length,
  } as { text: string; isValid: boolean });

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (name === "address") {
      setGooglePlaces({ text: value, isValid: true });
    }

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const onGooglePlacesTextChange = (e: any) => {
    const text = e.target.value;
    setAddressDirty(true);

    // Input is invalid until the onPlaceSelected is fired
    setGooglePlaces({ text, isValid: false });
  };

  const handleClose = () => {
    setFormState({});
    setAddressDirty(false);
    onClose();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!googlePlaces.isValid && addressDirty) {
      toast(errorToast("Please enter a valid address"));
      return;
    }

    setLoading(true);

    const obj = { ...company, ...formState };
    const payload = {
      name: obj.name,
      url: obj.url,
      address: obj.address,
      country: obj.country,
    };

    await adminCompanyService
      .updateCompany(payload)
      .then(() => {
        toast(successToast("Company updated"));
        onSubmit(payload);
        dispatch(update({ ...company, country: obj.country }));
      })
      .catch(() => {
        toast(successToast("Error updating company"));
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const init = async () => {};

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal width="550px" isOpen={isOpen} header="Edit company" onClose={handleClose}>
      <Loading loading={loading} fill ignoreDelay />
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <FormInput
            required
            title="Name"
            name="name"
            defaultValue={company.name}
            onChange={onInputChange}
          />
          <FormInput
            required
            name="url"
            title="Company url"
            defaultValue={company.url}
            onChange={onInputChange}
          />
          <GooglePlacesDropdown
            name="address"
            title="Main address"
            isInvalid={!googlePlaces.isValid && addressDirty}
            invalidInputMessage="Please enter a valid address"
            defaultValue={company.address?.name}
            onPlaceSelected={onInputChange}
            onChange={onGooglePlacesTextChange}
          />
          
          <CountryDropdownInput
            name="country"
            title="Country"
            defaultValue={company.country}
            onChange={onInputChange}
          />

          <Flex gap="12px" justifyContent="flex-end">
            <ButtonCustom secondary neutral onClick={onClose}>
              Cancel
            </ButtonCustom>
            <ButtonCustom type="submit">Confirm</ButtonCustom>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};
