import { Flex, Image } from "@chakra-ui/react";
import { CustomProfileCard } from "../../components/app/account/profile/AccountProfileCard";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { AccountUserCard } from "../../components/app/account/user/AccountUserCard";
import { Role } from "../../models/enum/role.enum";

export const Account = ({}) => {
  const { role } = useSelector((state: RootState) => state.userProfile);

  return (
    <Flex flexDir="row" w="100%" justifyContent="center">
      <Flex gap="24px" pt="30px">
        <Flex pos="relative" direction="column" gap="24px">
          <Image
            pos="absolute"
            src="/assets/account-bg.png"
            w="100%"
            borderTopRadius="8px"
          />

          <CustomProfileCard />
          {role === Role.ADMIN && <AccountUserCard />}
        </Flex>
      </Flex>
    </Flex>
  );
};
