import {
  Center,
  Flex,
  Image,
  useToast,
  Text,
  Box,
  Spinner,
  Grid,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { errorToast } from "../../constants/toast.constants";
import { IdentityService } from "../../service/identity/identity.service";
import TokenService from "../../service/identity/token.service";
import { MagiclinkService } from "../../service/magiclink/magiclink.service";
import { update } from "../../store/slice/Account/user-profile.slice";
import { getQueryParam } from "../../util/url.util";
import { tokenSlice } from "../../store/slice/Token/token.slice";
import { companySlice } from "../../store/slice/Account/company.slice";

import BasicLayout from "../../components/layout/basic.layout";
import { CompanyService } from "../../service/company/company.service";
import { updateDashboard } from "../../store/slice/Account/dashboard.slice";
import { updateActiveForm } from "../../store/slice/Account/inventory.slice";

export const LoginRedirect: React.FC<any> = (props: any) => {
  const identityService = new IdentityService();
  const magiclinkService = new MagiclinkService();
  const tokenService = new TokenService();
  const companyService = new CompanyService();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const identityLogin = async (token: string | null) => {
    if (!token) {
      toast(errorToast("Failed token authentication. Please try again", 7500));
      navigate("/app/auth/login");
    }

    await identityService
      .login(token!)
      .then(async (userProfile) => {
        dispatch(update(userProfile as any));

        // in case of register screen
        if (userProfile) {
          const accessToken = await tokenService
            .getAccessToken(token!)
            .catch((_) => {
              navigate("/app/auth/login");
            });

          dispatch(tokenSlice.actions.update({ accessToken } as any));
          dispatch(updateActiveForm({ formId: userProfile.forms.inventory }));

          dispatch(
            companySlice.actions.update({
              country: userProfile.company.country,
              name: userProfile.company.name,
              photoUri: userProfile.company.photoUri,
              isHeadquarters: userProfile.company.isHeadquarters,
              rebateRequired: userProfile.company.rebateRequired,
            })
          );

          await companyService.getCredits().then((resp) => {
            dispatch(updateDashboard(resp));
          });
        }

        const companyExists = !!userProfile.company.id;
        const path = companyExists
          ? "/app/dashboard"
          : "/app/auth/company/register";

        navigate(path);
      })
      .catch((err) => {
        const isSocialLogin = getQueryParam("social_login");
        if (isSocialLogin) {
          alert("Account not found. Please register first.");
        }

        navigate("/app/auth/login");
      });
  };

  const finishEmailRedirectLogin = () => {
    const magicCredential = getQueryParam("magic_credential");
    const isSocialLogin = getQueryParam("social_login");

    if (!magicCredential) navigate("/app/auth/login");

    if (magicCredential) {
      if (isSocialLogin) {
        magiclinkService.magicSocial?.oauth
          .getRedirectResult()
          .then(async (auth) => await identityLogin(auth.magic.idToken));

        return;
      }

      magiclinkService.magic?.auth
        .loginWithCredential()
        .then(async (didToken) => await identityLogin(didToken));
    }
  };

  useEffect(() => {
    finishEmailRedirectLogin();
  }, []);

  return (
    <BasicLayout>
      <Flex flexDirection="column" gap="16px">
        <Flex textAlign="center" flexDirection="column" gap="6px">
          <Text fontSize="26px" fontWeight="700" color="brand.yellow.300">
            Re-imagine E-Waste Management
          </Text>
        </Flex>

        <Center>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="brand.green.200"
            color="brand.yellow.300"
            size="xl"
          />
        </Center>

        <Center>
          <Grid
            mt="20px"
            w={{ base: undefined, md: "45vw" }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            gap="12px"
          >
            <Box>
              <Image src="/assets/homepage-pickup.svg" alt="Pickup" />
            </Box>
            <Box color="white" fontSize="20px">
              <Text fontWeight="700" fontSize="26px">
                Single Touch Recycling With Zolo Single Touch
              </Text>
              Recycling is as easy as clicking a button. Upload your e-waste
              inventory and let us handle the complex processes. Your IT team
              can focus on what they do best while we take care of the rest.
            </Box>
          </Grid>
        </Center>
      </Flex>
    </BasicLayout>
  );
};
