import React from "react";
import { Box } from "@chakra-ui/react";
import styles from "../../styles/Home.module.css";

const BasicLayout: React.FC<any> = ({ children, bg }) => {
  return (
    <Box
      className={styles.main + " " + styles["main-content"]}
      backgroundColor={bg ?? "brand.green.200"}
    >
      <Box pl={{ base: 0 }}>{children}</Box>
    </Box>
  );
};

export default BasicLayout;
