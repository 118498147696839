import { Flex, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

export const DropdownInput: React.FC<any> = (props: {
  name?: string;
  title?: string;
  placeholder?: string;
  size?: "sm" | "md" | "lg";
  options: any; // Takes an enum
  styles?: any;
  defaultValue?: any;
  emptyStart?: boolean;
  required?: boolean;
  onChange?: (e: any) => void;
}) => {
  const { defaultValue } = props;
  const [val, setVal] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      setVal(defaultValue);
    }
  }, [defaultValue]);

  const constructValues = () => {
    const options =
      props.emptyStart ?? true
        ? [<option key="" value=""></option>]
        : ([] as any[]);
    Object.keys(props.options).forEach((key) => {
      options.push(
        <option key={key} value={key}>
          {props.options[key]}
        </option>
      );
    });

    return options;
  };

  return (
    <Flex flexDirection={"column"} flexGrow={"1"}>
      {props.title ? (
        <Text fontWeight={600} fontSize="15px" mb="8px">
          {props.title}
          {props.required ? (
            <Text
              as={"span"}
              fontWeight="700"
              fontSize="14px"
              color="#583CE1"
              ml="3px"
            >
              *
            </Text>
          ) : (
            <></>
          )}
        </Text>
      ) : (
        <></>
      )}

      {val ? (
        <Select
          required={props.required}
          onChange={props.onChange}
          name={props.name}
          {...props.styles}
          defaultValue={val}
          size={props.size ?? "md"}
          placeholder={props.placeholder}
        >
          {constructValues()}
        </Select>
      ) : (
        <></>
      )}

      {!val ? (
        <Select
          required={props.required}
          onChange={props.onChange}
          name={props.name}
          {...props.styles}
          size={props.size ?? "md"}
          placeholder={props.placeholder}
        >
          {constructValues()}
        </Select>
      ) : (
        <></>
      )}
    </Flex>
  );
};
