export const sortDirection = (
  items: any[],
  accessor: string,
  direction?: 0 | 1
) => {
  if (direction === undefined) return items;

  return items.sort((a, b) => {
    const aVal = a[accessor];
    const bVal = b[accessor];

    // Prioritize null/undefined to be "minimum" values
    if (aVal === undefined || aVal === null) return direction === 0 ? -1 : 1;
    if (bVal === undefined || bVal === null) return direction === 0 ? 1 : -1;

    if (typeof aVal === "string" && typeof bVal === "string") {
      // Check if both strings are numbers
      if (!isNaN(Number(aVal)) && !isNaN(Number(bVal))) {
        return direction === 0
          ? Number(aVal) - Number(bVal)
          : Number(bVal) - Number(aVal);
      }

      // Use localeCompare for case-insensitive string comparison
      return direction === 0
        ? aVal.localeCompare(bVal, undefined, { sensitivity: "base" })
        : bVal.localeCompare(aVal, undefined, { sensitivity: "base" });
    } else if (typeof aVal === "number" && typeof bVal === "number") {
      // Use simple subtraction for number comparison
      return direction === 0 ? aVal - bVal : bVal - aVal;
    }

    // Fallback: treat as strings but use simple comparison (case-sensitive)
    return direction === 0
      ? `${aVal}` < `${bVal}`
        ? -1
        : 1
      : `${bVal}` < `${aVal}`
      ? -1
      : 1;
  });
};
