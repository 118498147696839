import { createSlice } from "@reduxjs/toolkit";

export interface UserProfile {
  userId: string;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  photoUri?: string;
  mobile?: string;
  role: string;
}

export const initialState: UserProfile = {
  userId: "",
  displayName: "",
  firstName: "",
  lastName: "",
  email: "",
  role: "",
};

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    update: (state, params) => {
      const f = params.payload as UserProfile;

      state.userId = f.userId;
      state.email = f.email;
      state.photoUri = f.photoUri;
      state.displayName = f.displayName;
      state.firstName = f.firstName;
      state.lastName = f.lastName;
      state.mobile = f.mobile;
      state.role = f.role;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = userProfileSlice.actions;

export default userProfileSlice.reducer;
