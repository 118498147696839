import { BaseService } from "../base.service";

export default class TokenService extends BaseService {
  constructor() {
    super();
  }

  async getAccessToken(didToken: string) {
    const resp = await this.client.post(
      "/identity/token",
      {},
      {
        headers: { Authorization: `Bearer ${didToken}` },
      }
    );
    return resp.data.accessToken;
  }

  async getAssumeAccessToken(otpToken: string) {
    const resp = await this.client.post(
      "/identity/admin/assume",
      {},
      {
        headers: { Authorization: `Bearer ${otpToken}` }, // Gets verified via public key at APIG level
      }
    );
    return resp.data;
  }
}
