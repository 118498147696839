import { Flex, Box, SimpleGrid, Divider } from "@chakra-ui/react";
import { StatusPickerInput } from "../../form/controls/status-picker.input";
import { InventoryStatus } from "../../../models/enum/inventory/inventory-status.enum";
import { convertIsoTime } from "../../../util/date.util";
import { DownloadIcon } from "@chakra-ui/icons";

export const InventoryPreviewDrawer = ({
  inventory,
  onOpen,
  onOpenDestructionPreview,
}) => {
  return (
    <Flex flexDir="column" justifyContent="space-between">
      <Flex
        flexDir="column"
        gap={2}
        w="21%"
        h="98vh"
        p={4}
        borderRadius={8}
        zIndex={9999}
        minH="90%"
        pos="fixed"
        overflowY="auto"
        border="1px solid #ECECEC"
        top="1vh"
        right="10px"
        bg="white"
        boxShadow="0 0 10px 1px #ECECEC"
      >
        <Flex flexDir="row" justifyContent="space-between">
          <Box>
            <Flex flexDir="row" gap={2}>
              <Box fontWeight="500" fontSize="20px">
                # {inventory.qrNo}
              </Box>
              <Box>
                <StatusPickerInput
                  id={undefined}
                  status={inventory.status}
                  options={InventoryStatus}
                />
              </Box>
            </Flex>
            <Box fontWeight="500" fontSize="14px">
              ID: {inventory.id}
            </Box>
            <Box fontWeight="500" fontSize="14px">
              Created: {convertIsoTime(inventory.createdDate)}
            </Box>

            {onOpenDestructionPreview && (
              <Flex
                mt="12px"
                flexDir="row"
                gap="1"
                cursor="pointer"
                alignItems="center"
                onClick={onOpenDestructionPreview}
                bg="brand.yellow.200"
                w="fit-content"
                p="3px 6px"
                fontSize="13px"
                borderRadius="4px"
                fontWeight="500"
              >
                <DownloadIcon />
                View Data Erasure Report
              </Flex>
            )}
          </Box>

          <Box cursor="pointer" onClick={onOpen}>
            Close
          </Box>
        </Flex>

        <Divider />

        <SimpleGrid columns={2} gap={2}>
          <ItemRow label="Category">{inventory.category}</ItemRow>
          <ItemRow label="Brand">{inventory.brand}</ItemRow>
          <ItemRow label="Make">{inventory.make}</ItemRow>
          <ItemRow label="Model">{inventory.model}</ItemRow>
          <ItemRow label="Serial #">{inventory.serialNumber}</ItemRow>
          <ItemRow label="Platform">{inventory.platform}</ItemRow>
          <ItemRow label="OS Vers.">{inventory.osVersion}</ItemRow>
          <ItemRow label="Processor">{inventory.processor}</ItemRow>
          <ItemRow label="RAM">{inventory.ram}</ItemRow>
          <ItemRow label="Storage Size">{inventory.storageSize}</ItemRow>
          <ItemRow label="Storage Type">{inventory.storageType}</ItemRow>
          <ItemRow label="Year">{inventory.year}</ItemRow>
          <ItemRow label="Display Size">{inventory.displaySize}</ItemRow>
          <ItemRow label="Harddrive Serial #">
            {inventory.harddriveSerialNumber}
          </ItemRow>
          <ItemRow label="Erase Method">{inventory.eraseMethod}</ItemRow>
          <ItemRow label="Disk Verification">
            {inventory.diskVerification}
          </ItemRow>
          <ItemRow label="Sanitisation">{inventory.sanitisation}</ItemRow>
          <ItemRow label="Destruction Method">
            {inventory.destructionMethod}
          </ItemRow>
        </SimpleGrid>
        <ItemRow label="Notes">{inventory.notes}</ItemRow>

        <Box fontSize="12px" color="gray">
          The decommissioning of this device has achieved Zolo GreenGuard™
          Certification, ensuring secure data erasure and compliance with
          rigorous Environmental, Social, and Governance (ESG) standards.
        </Box>
      </Flex>
    </Flex>
  );
};

export const ItemRow = ({ label, children }) => (
  <Flex flexDir="column" justifyContent="space-between">
    <Box fontWeight="600" fontSize="14px">
      {label}
    </Box>
    <Box>{children ?? "-"}</Box>
  </Flex>
);
