import { CompanyModel } from "../../models/company.model";
import { DashboardModel } from "../../models/dashboard.model";
import { UserProfile } from "../../models/user-profile.model";
import { BaseService } from "../base.service";

export class CompanyService extends BaseService {
  constructor() {
    super();
  }

  async updateCompany(data: { name; url }) {
    await this.client.put(`/company`, data);
  }

  async getCompany(): Promise<CompanyModel> {
    const resp = await this.client.get(`/company`);
    return resp.data;
  }

  async registerCompany(didToken: string, company: any, photoData: any) {
    return await new Promise<UserProfile>(
      async (resolve, reject) =>
        await this.client
          .post(
            "/company/register",
            { ...company },
            {
              headers: { Authorization: `Bearer ${didToken}` },
            }
          )
          .then(async (res) => {
            if (photoData) {
              await this.client.post(
                `/company/register/logo?companyId=${res.data.companyId}`,
                photoData,
                {
                  headers: {
                    Authorization: `Bearer ${didToken}`,
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
            }

            resolve(res.data);
          })
    );
  }

  async updateLogo(data: any) {
    await this.client.post(`/company/media`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async getCredits(): Promise<{
    credits: number;
    carbonCredits: number;
    monthEmission: number;
  }> {
    const resp = await this.client.get(`/company/credits`);
    return resp?.data;
  }

  async getDashboard(): Promise<DashboardModel> {
    const resp = await this.client.get(`/company/dashboard`);
    return resp?.data;
  }

  async globalSearch(term: string) {
    const resp = await this.client.post(`/company/search`, {
      filter: { term },
    });

    return resp?.data;
  }
}

export const companyService = new CompanyService();
