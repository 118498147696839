import { createSlice } from "@reduxjs/toolkit";

export interface UserRegistration {
  firstName: string;
  lastName: string;
  email: string;
  regToken: string;
  didToken: string;
}

export const userRegistrationInitialState: UserRegistration = {
  firstName: "",
  lastName: "",
  email: "",
  regToken: "",
  didToken: "",
};

export const userRegistrationSlice = createSlice({
  name: "userRegistration",
  initialState: userRegistrationInitialState,
  reducers: {
    updateUserRegistration: (state, params) => {
      const f = params.payload as UserRegistration;

      state.firstName = f.firstName;
      state.lastName = f.lastName;
      state.email = f.email;
      state.regToken = f.regToken;
      state.didToken = f.didToken;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUserRegistration } = userRegistrationSlice.actions;

export default userRegistrationSlice.reducer;
