import {
  useToast,
  Flex,
  Box,
  Grid,
  Button,
  Tooltip,
  Badge,
} from "@chakra-ui/react";
import { useState, useEffect, useContext } from "react";
import {
  successToast,
  errorToast,
} from "../../../../constants/toast.constants";
import { CompanyModel } from "../../../../models/company.model";
import { CompanyService } from "../../../../service/company/company.service";
import { AvatarCustom } from "../../../form/avatar.custom";
import { CompanyEditModal } from "./company-edit.modal";
import { AdminCompanyService } from "../../../../service/admin/admin-company.service";
import { CardTitle } from "../../../shared/CardTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { Role } from "../../../../models/enum/role.enum";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import BusinessCenterOutlined from "@mui/icons-material/BusinessCenterOutlined";
import { CompostIcon } from "../../../../custom-assets";
import { LanguageOutlined, LocationOnOutlined } from "@mui/icons-material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { UserService } from "../../../../service/user/user.service";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { AppContext } from "../../../../App";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const CustomCompanyCard = () => {
  const companyService = new CompanyService();
  const adminCompanyService = new AdminCompanyService();
  const userService = new UserService();
  const toast = useToast();

  const { role } = useSelector((state: RootState) => state.userProfile);
  const { isHeadquarters, country } = useSelector(
    (state: RootState) => state.company
  );

  const [company, setCompany] = useState({} as CompanyModel);
  const [photoUri, setPhotoUri] = useState(null as string);
  const [isOpen, setIsOpen] = useState(false);
  const [complete, setComplete] = useState(true);
  const [loading, setLoading] = useState(true);

  const init = async () => {
    setLoading(true);
    const company = await companyService.getCompany();
    setCompany(company);
    setPhotoUri(company.photoUri);

    await userService.profileComplete().then((res) => {
      setComplete(res.companyComplete);
    });

    setLoading(false);
  };

  const handlePhotoChange = async ({ formData, photoUri }) => {
    await adminCompanyService
      .updateLogo(formData)
      .then((res) => {
        setPhotoUri(photoUri);
        toast(successToast("Company logo updated"));
      })
      .catch((err) => {
        toast(errorToast("Error updating company logo"));
      });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Box maxW="600px">
      <Flex mb="24px" justifyContent="space-between" alignItems="center">
        <CardTitle>
          <Flex alignItems="center" flexDir="row" gap="8px">
            Company details
          </Flex>
        </CardTitle>
        {role === Role.ADMIN && (
          <Flex flexDir="row" gap="4px">
            {!complete && (
              <Tooltip
                label="Please complete your profile"
                aria-label="A tooltip"
              >
                <ErrorOutlineOutlinedIcon
                  style={{ color: "red", fontSize: "28px" }}
                />
              </Tooltip>
            )}
            <Button
              onClick={() => setIsOpen(true)}
              sx={{
                fontSize: "14px",
                padding: "5px",
                h: "fit-content",
                backgroundColor: "transparent",
                border: "1px solid lightGray",
              }}
            >
              Edit Company
            </Button>
            <Button
              hidden={!isHeadquarters}
              onClick={() =>
                window.open(
                  `http://${window.location.host}/app/branch`,
                  "_blank"
                )
              }
              sx={{
                fontSize: "14px",
                padding: "5px",
                h: "fit-content",
                backgroundColor: "transparent",
                border: "1px solid lightGray",
              }}
            >
              <OpenInNewOutlinedIcon
                style={{ fontSize: "14px", marginRight: "4px" }}
              />
              Branches
            </Button>
          </Flex>
        )}
      </Flex>
      <Flex flexDir="row" gap="36px">
        <AvatarCustom
          src={photoUri ?? "/assets/placeholder.png"}
          allowUpload={role === Role.ADMIN}
          onUpload={handlePhotoChange}
        />
        <Box>
          <Flex
            fontFamily="zolo-header"
            flexDir="column"
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight="bold"
          >
            {company.name}
            <Badge
              my="4px"
              borderRadius="8px"
              fontSize="12px"
              px="8px"
              h="fit-content"
              w="fit-content"
              bg="brand.yellow.200"
              color="brand.green.300"
            >
              {isHeadquarters ? "Headquarters" : "Branch"}
            </Badge>
          </Flex>
          <Flex
            hidden={!company.url?.length}
            gap="4px"
            color="brand.gray.400"
            fontWeight="bold"
          >
            <Box mt="2px">
              <LanguageOutlined fontSize="small" />
            </Box>
            {company.url}
          </Flex>
          <Flex hidden={!company.address?.name} color="brand.gray.400" mb={4}>
            <Box mt="2px">
              <LocationOnOutlined fontSize="small" />
            </Box>
            {company.address?.name}
          </Flex>
        </Box>
      </Flex>

      <CardsSection company={company} />

      <Flex
        flexDir="row"
        gap="4px"
        pt="16px"
        fontSize="12px"
        color="brand.gray.400"
      >
        <InfoOutlinedIcon style={{ fontSize: "14px", marginTop: "3px" }} />
        <Box>
          The carbon avoidance figures are derived from industry-standard
          estimates and generalised data related to the production and lifecycle
          emissions of the devices you've recycled.
        </Box>
      </Flex>

      <CompanyEditModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        company={company}
        onSubmit={(companyData: any) => {
          setCompany({ ...company, ...companyData });
          setComplete(true);
        }}
      />
    </Box>
  );
};

const CardsSection = ({ company }) => {
  const { currencySymbol } = useContext(AppContext);
  const { rebateRequired } = useSelector(
    (state: RootState) => state.company
  );

  return (
    <Grid
      mt="16px"
      templateColumns={["repeat(2, 1fr)", "repeat(3, 1fr)"]}
      gap="16px"
    >
      {rebateRequired && (
        <Detail
        label="Credits"
        value={`${currencySymbol}${company.credits}`}
        icon={<PaidOutlinedIcon />}
        />
      )}
      <Detail
        label="Emission (total)"
        value={`${company.totalEmission ?? 0}kg`}
        icon={<CompostIcon color="#e29c56" />}
      />

      <Detail
        label="Inventory"
        value={company.totalInventory}
        icon={<BusinessCenterOutlined />}
      />
      {/* 
      <Detail
        label="Total sales"
        value={`$${company.totalSalePrice ?? 0}`}
        icon={<PaidOutlinedIcon />}
      /> */}
    </Grid>
  );
};

const Detail = ({ label, value, icon }) => {
  return (
    <Flex
      flexDirection="column"
      w={{ base: "155px", md: "173px" }}
      h="96px"
      border="1px solid lightGray"
      borderRadius="4px"
      p="12px"
    >
      <Flex flexDir="row" justifyContent="space-between">
        <Box fontSize={{ base: "14px", md: "16px" }} color="brand.gray.400">
          {label}
        </Box>
        <Box color="brand.brown.100">{icon}</Box>
      </Flex>

      <Box
        fontSize={{ base: "17px", md: "20px" }}
        fontWeight="bold"
        color={value ? "brand.green.200" : "brand.gray.400"}
        fontFamily="zolo-header"
      >
        {value ?? "N/A"}
      </Box>
    </Flex>
  );
};
