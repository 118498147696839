import { useState, useEffect } from "react";
import { AdminUserService } from "../../../../service/admin/admin-user.service";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useToast,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  Divider,
  Tooltip,
  Grid,
} from "@chakra-ui/react";
import { ButtonCustom } from "../../../form/controls/form.button";
import { IconCustom } from "../../../shared/IconCustom";
import { CardCustom } from "../../../shared/CardCustom";
import { CardTitle } from "../../../shared/CardTitle";
import { InviteUserModal } from "../inviteUser.modal";
import {
  errorToast,
  successToast,
} from "../../../../constants/toast.constants";
import AlertDialog from "../../../shared/AlertDialog";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import AddIcon from "@mui/icons-material/Add";
import { Role } from "../../../../models/enum/role.enum";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Separator } from "../../../shared/Separator";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { isInternalAdmin } from "../../../../util/zolo.util";

export const AccountUserCard = ({}) => {
  const userService = new AdminUserService();
  const { role, email } = useSelector((state: RootState) => state.userProfile);

  const [users, setUsers] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [userToRevoke, setUserToRevoke] = useState("");
  const [userToDeactivate, setUserToDeactivate] = useState("");
  const [deactivateAlert, setDeactivateAlert] = useState(false);
  const [activateAlert, setActivateAlert] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();

  const toast = useToast();

  const init = async () => {
    const users = await userService.getCompanyUsers();
    const invitedUsers = await userService.getInvitedList();

    setUsers(users);
    setInvitedUsers(invitedUsers);
  };

  useEffect(() => {
    init();
  }, []);

  const handleRevoke = async (email: string) => {
    await userService
      .revokeInvite(email)
      .then(() => {
        alertOnClose();
        toast(successToast(`${email} invitation has been revoked.`));
      })
      .catch((err) => {
        console.log(err);
        toast(errorToast(`Something went wrong. Please try again.`));
      })
      .finally(() => {
        init();
      });
  };

  const handleDeactivate = async (email: string) => {
    await userService
      .deactivate({ email })
      .then(() => {
        setDeactivateAlert(false);
        toast(successToast(`${email} has been deactivated.`));
      })
      .catch((err) => {
        console.log(err);
        toast(errorToast(`Something went wrong. Please try again.`));
      })
      .finally(() => {
        init();
      });
  };

  const handleActivate = async (email: string) => {
    await userService
      .activate({ email })
      .then(() => {
        setActivateAlert(false);
        toast(successToast(`${email} has been activated.`));
      })
      .catch((err) => {
        console.log(err);
        toast(errorToast(`Something went wrong. Please try again.`));
      })
      .finally(() => {
        init();
      });
  };

  const handleRoleSelect = async (role: string, email: string) => {
    await userService
      .updateRole({ email, role })
      .then(() => {
        init();
        toast(successToast(`${email} has been updated to ${Role[role]}.`));
      })
      .catch((err) => {
        console.log(err);
        toast(errorToast(`Something went wrong. Please try again.`));
      });
  };

  const columns = [
    {
      header: "Email",
      accessor: "email",
      callback: (email, user) => {
        if (isInternalAdmin(user.email) && user.deleted) {
          return;
        }

        return (
          <Flex
            color={user.deleted ? "#A0AEBF" : undefined}
            flexDir="row"
            w="300px"
            gap="4px"
          >
            {user.deleted ? (
              <CancelIcon style={{ fontSize: "28px", color: "#A0AEBF" }} />
            ) : (
              <IconCustom fileName="check" />
            )}

            {email}
          </Flex>
        );
      },
    },
    {
      header: "",
      accessor: "roleName",
      callback: (role, user) => {
        if (isInternalAdmin(user.email) && user.deleted) {
          return;
        }

        return user.email !== email ? (
          <Flex
            justifyContent="flex-start"
            w="150px"
            color={user.deleted ? "#A0AEBF" : undefined}
          >
            <Menu>
              <MenuButton
                as={Button}
                variant="ghost"
                rightIcon={<ChevronDownIcon />}
                fontWeight="normal"
                p={1}
                height="inherit"
              >
                {role}
              </MenuButton>
              <MenuList hidden={user.deleted}>
                {(Object.keys(Role) as Array<Role>).map((key, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      role !== Role[key] && handleRoleSelect(key, user.email);
                    }}
                  >
                    {Role[key]}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
        ) : (
          <Box p={1}>{role}</Box>
        );
      },
    },
    role === Role.ADMIN && {
      accessor: "deleted",
      callback: (deleted, user) => {
        if (user.email === email) {
          return;
        }
        // hack to remove deactivate zolo staff
        if (isInternalAdmin(user.email) && deleted) {
          return;
        }

        return (
          <>
            {deleted ? (
              <Tooltip label="Reactivate" placement="bottom">
                <Box
                  color="gray"
                  cursor="pointer"
                  _hover={{ color: "brand.brown.100" }}
                >
                  <BoltOutlinedIcon
                    onClick={() => {
                      handleActivate(user.email);
                      setActivateAlert(true);
                    }}
                  />
                </Box>
              </Tooltip>
            ) : (
              <Tooltip label="Deactivate" placement="bottom">
                <Box
                  color="gray"
                  cursor="pointer"
                  _hover={{ color: "brand.brown.100" }}
                >
                  <DeleteOutlineIcon
                    fontSize="small"
                    onClick={() => {
                      setUserToDeactivate(user.email);
                      setDeactivateAlert(true);
                    }}
                  />
                </Box>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <CardCustom gap="6px" px={{ base: "16px", md: "40px" }}>
        <Flex justifyContent="space-between" alignItems="center">
          <CardTitle>
            Users ({users?.length + invitedUsers?.length ?? 0})
          </CardTitle>
          <ButtonCustom
            hidden={role !== Role.ADMIN}
            onClick={onOpen}
            neutral
            style={{
              width: "120px",
              height: "30px",
              backgroundColor: "transparent",
            }}
          >
            <AddIcon />
            Invite user
          </ButtonCustom>
        </Flex>
        <TableContainer w="100%">
          <Table
            variant="simple"
            mt="24px"
            display={{ base: "none", md: "block" }}
          >
            <Tbody w="100%">
              {users.map((row, i) => (
                <Tr key={i}>
                  {columns.map((column, j) => (
                    <Td
                      key={j}
                      py={1}
                      mr="36px"
                      pl={j === 0 && 0}
                      borderBottom="none"
                    >
                      {column.callback ? (
                        <>{column.callback(row[column.accessor], row)}</>
                      ) : (
                        <>{row[column.accessor]}</>
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>

          <Box mt="14px" display={{ base: "block", md: "none" }}>
            {users.map((row, i) => (
              <Flex
                my="4px"
                gap="4px"
                p="16px"
                border="1px solid lightGray"
                borderRadius="6px"
                flexDir="column"
                key={i}
              >
                {columns.map((column, j) => (
                  <Box key={j} p={column.accessor === "notes" && 0}>
                    {column.callback ? (
                      <Flex flexDir="row" gap="6px">
                        {column.callback(row[column.accessor], row)}
                      </Flex>
                    ) : (
                      <Flex flexDir="row" gap="6px">
                        {column.header} {row[column.accessor]}
                      </Flex>
                    )}
                  </Box>
                ))}
              </Flex>
            ))}
          </Box>

          <Box hidden={!invitedUsers.length}>
            <Divider my="26px" orientation="horizontal" />
            <Box>
              <Separator />
              <CardTitle fontSize="24px">
                Invited ({invitedUsers.length})
              </CardTitle>
            </Box>

            <Table mt="26px" hidden={!invitedUsers?.length} variant="simple">
              <Tbody>
                {invitedUsers.map((row, i) => (
                  <Tr px="0" key={i}>
                    <Flex flexDir="row" justifyContent="space-between">
                      <Td color="gray" px="0" py={1} borderBottom="none">
                        <Flex flexDir="row" gap="4px" alignItems="center">
                          <HelpOutlineOutlinedIcon />
                          {row.email}
                        </Flex>
                      </Td>

                      <Td
                        py={1}
                        borderBottom="none"
                        color="gray"
                        cursor="pointer"
                        _hover={{ color: "brand.brown.100" }}
                      >
                        <Tooltip label="Revoke" placement="bottom">
                          <DeleteOutlineIcon
                            fontSize="small"
                            onClick={() => {
                              setUserToRevoke(row.email);
                              alertOnOpen();
                            }}
                          />
                        </Tooltip>
                      </Td>
                    </Flex>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </TableContainer>
      </CardCustom>
      <InviteUserModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={() => {
          init();
          onClose();
        }}
      />
      <AlertDialog
        isOpen={alertIsOpen}
        onClose={alertOnClose}
        title={`Revoke invite for ${userToRevoke}`}
        colorScheme="red"
        confirmText="Revoke"
        onConfirm={() => handleRevoke(userToRevoke)}
      >
        Are you sure? You can't undo this action.
      </AlertDialog>
      <AlertDialog
        isOpen={deactivateAlert}
        onClose={() => setDeactivateAlert(false)}
        title={`Deactivate ${userToDeactivate}`}
        colorScheme="red"
        confirmText="Deactivate"
        onConfirm={() => handleDeactivate(userToDeactivate)}
      >
        Are you sure? You can't undo this action.
      </AlertDialog>
    </>
  );
};
