import { Avatar, AvatarBadge, Icon, Image } from "@chakra-ui/react";
import { useState } from "react";
import { FaCamera } from "react-icons/fa";
import { compressImage, previewImageBlob } from "../../util/file.util";
import { ImageUploadIcon } from "./image-upload.icon";

const initialUploadObj = {
  formData: {},
  uploaded: false,
};

export const AvatarCustom: React.FC<any> = (props: {
  entityId: string;
  src: string;
  allowUpload?: boolean;
  widthxheight?: string;
  border?: string;
  filter?: string;
  onUpload?: (formData: any) => void;
  styles?: any;
}) => {
  const { entityId, src, allowUpload, onUpload, styles } = props;
  const [submitErrors, setSubmitErrors] = useState([] as string[]);
  const [uploadState, setUploadState] = useState(initialUploadObj);
  const [photoUri, setPhotoUri] = useState(null as string | null);

  const onDrop = async (acceptedFiles: any) => {
    const formData = new FormData();
    let file = await compressImage(acceptedFiles[0]);

    formData.append("file", file);

    const imageUri = (await previewImageBlob(file)) as string;
    setPhotoUri(imageUri);

    setUploadState({
      formData: formData,
      uploaded: true,
    });

    if (onUpload) onUpload({ formData, photoUri: imageUri });
  };

  return (
    <>
      <Avatar
        src={photoUri ?? src}
        w={props.widthxheight ? props.widthxheight : "80px"}
        h={props.widthxheight ? props.widthxheight : "80px"}
        border={props.border ? props.border : "none"}
        {...styles}
      >
        {allowUpload ? (
          <AvatarBadge boxSize="40px" bg="brand.green.100">
            <ImageUploadIcon
              onDrop={onDrop}
              accept={{ "image/*": [".jpeg", ".jpg", ".png"] }}
              maxFiles={1}
              isInvalid={submitErrors.includes("upload-error")}
            >
              <Icon as={FaCamera} fill="white" bg="transparent" />
            </ImageUploadIcon>
          </AvatarBadge>
        ) : (
          <></>
        )}
      </Avatar>
    </>
  );
};
