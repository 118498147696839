import { Box, Flex, Heading } from "@chakra-ui/react";

export const CardTitle = ({ children, divider = true, ...props }) => {
  return (
    <Flex flexDir="column" gap="4px">
      <Box
        fontSize="24px"
        style={{ fontFamily: "zolo-header" }}
        color="brand.green.200"
        {...props}
      >
        {children}
      </Box>
      <Box hidden={!divider} bg="brand.yellow.300" h="3px" w="40px"></Box>
    </Flex>
  );
};
