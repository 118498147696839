import { useEffect, useState, useContext } from "react";
import {
  Box,
  Divider,
  Flex,
  Tbody,
  Td,
  Text,
  Tr,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { ButtonCustom } from "../../components/form/controls/form.button";
import { TableContainer } from "../../components/shared/table/TableContainer";
import { update } from "../../store/slice/Account/company.slice";
import { sortDirection } from "../../util/list.util";
import { AddBranchModal } from "../../components/app/company/add-branch.modal";
import { ellipsesText } from "../../util/string.util";
import { TableContainerMobile } from "../../components/shared/table/TableContainerMobile";
import { Role } from "../../models/enum/role.enum";
import { EmptyContent } from "../../components/shared/EmptyContent";
import { branchService } from "../../service/company/branch.service";
import BusinessIcon from "@mui/icons-material/Business";
import { AppContext } from "../../App";

export const Branch = ({}) => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([] as any);
  const [addBranchOpen, setAddBranchOpen] = useState(false);
  const [dashboard, setDashboard] = useState({} as any);
  const { currencySymbol } = useContext(AppContext);

  const dispatch = useDispatch();

  const { role } = useSelector((state: RootState) => state.userProfile);
  const companyState = useSelector((state: RootState) => state.company);
  const { sortColumn, isHeadquarters } = companyState;

  const navigate = useNavigate();

  const isLargeDisplay = useBreakpointValue({
    base: false,
    sm: false,
    md: true,
    lg: true,
    xl: true,
  });

  const handleSort = (props: { accessor: string; direction: 0 | 1 }) => {
    dispatch(
      update({
        ...companyState,
        sortColumn: { accessor: props.accessor, direction: props.direction },
      })
    );

    const sortedCompanies = sortDirection(
      companies,
      props.accessor,
      props.direction
    );

    setCompanies([...sortedCompanies]);
  };

  const columns = [
    {
      header: "Branch name",
      accessor: "name",
      width: "200px",
      callback: (companyName: string) => (
        <Box fontWeight="600">{companyName}</Box>
      ),
    },
    {
      header: "Job",
      width: "100px",

      accessor: "totalJobs",
      callback: (count: any) => <>{+(count ?? 0) === 0 ? "-" : count}</>,
    },
    {
      header: "User",
      width: "100px",
      accessor: "totalUsers",
      callback: (count: any) => <>{+count === 0 ? "-" : count}</>,
    },
    {
      header: "Inventory",
      width: "100px",
      accessor: "totalInventory",
      callback: (count: any) => <>{+count === 0 ? "-" : count}</>,
    },
    {
      header: "Address",
      width: "250px",
      accessor: "addressName",
      callback: (address: string) => <>{ellipsesText(address, 150)}</>,
    },
  ];

  useEffect(() => {
    if (!isHeadquarters) {
      navigate("/");
    }
  }, [isHeadquarters]);

  const init = async () => {
    setLoading(true);
    await branchService.getBranchList().then((companies) => {
      setCompanies(companies);
    });

    await branchService.getBranchesDashboard().then((dashboard) => {
      setDashboard(dashboard);
    });

    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Flex
        flexDir="column"
        color="gray"
        gap={2}
        bg="white"
        mb="24px"
        boxShadow="base"
        px={4}
        py={2}
        pb={6}
        borderRadius="md"
        width="100%"
      >
        <Flex
          flexDirection="row"
          gap="6px"
          w="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box color="black" fontSize="2xl" fontWeight="bold">
            Branches
          </Box>
          {role === Role.ADMIN ? (
            <ButtonCustom onClick={() => setAddBranchOpen(true)}>
              + Add branch
            </ButtonCustom>
          ) : (
            <Box></Box>
          )}
        </Flex>

        <Flex flexDir="row" gap="4px">
          <BusinessIcon />
          {companies?.length || 0} locations
        </Flex>

        <Divider my={2} mb={4} />

        <Flex flexDir="row" gap="20px">
          <DashboardCard
            type="weight"
            value={`${dashboard.totalWeight ?? 0}kg`}
            text={
              <Box>
                <Text>e-waste diverted</Text> from landfill
              </Box>
            }
          />

          <DashboardCard
            type="emission"
            value={`${(+(dashboard.totalEmission ?? 0)).toLocaleString(
              "en-US"
            )}kg`}
            text={
              <Box>
                <Text>total estimated</Text> C02 avoidance
              </Box>
            }
          />

          <DashboardCard
            type="count"
            value={`${dashboard.totalInventory ?? 0}`}
            text={
              <Box>
                <Text>total items</Text> re-purposed
              </Box>
            }
          />

          {companyState.rebateRequired && (
            <DashboardCard
              type="credits"
              value={`${currencySymbol}${(dashboard.credits ?? 0).toLocaleString(
                "en-US"
              )}`}
              text={
                <Box>
                  <Text fontSize="14px">buy-back</Text>credits
                </Box>
              }
            />
          )}
        </Flex>
      </Flex>

      {isLargeDisplay ? (
        <TableContainer
          columns={columns}
          onSort={handleSort}
          sortColumn={sortColumn}
          isEmpty={!loading && !companies?.length}
          emptyChildren={
            <Flex
              justifyContent="center"
              alignItems="center"
              minH="100%"
              minW="100%"
              border="1px solid #ECECEC"
              borderRadius="lg"
            >
              <EmptyContent
                title="No active branches"
                textAlign="center"
                mb={2}
              >
                <Box>
                  Branches help you manage your company's operations in
                  different locations.
                  <br />
                  Get started by adding a branch.
                </Box>
                <ButtonCustom onClick={() => setAddBranchOpen(true)}>
                  + Add branch
                </ButtonCustom>
              </EmptyContent>
            </Flex>
          }
        >
          <Tbody>
            {companies.map((row, i) => (
              <Tr
                _hover={{
                  cursor: "pointer",
                  backgroundColor: "#ECECEC",
                }}
                onClick={() => {
                  navigate(`/app/branch/${row.id}`);
                }}
                key={row.id}
              >
                {columns.map((column, j) => (
                  <Td key={j} height="50px">
                    {column.callback ? (
                      <>{column.callback(row[column.accessor])}</>
                    ) : (
                      <>{row[column.accessor]}</>
                    )}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </TableContainer>
      ) : (
        <TableContainerMobile columns={columns} data={companies} />
      )}

      <AddBranchModal
        isOpen={addBranchOpen}
        onClose={() => setAddBranchOpen(false)}
        onSubmit={init}
      />
    </>
  );
};

const DashboardCard = ({
  type,
  value,
  text,
  iconStyle = undefined,
  ...props
}) => {
  const styles = { fontSize: "56px" };

  const Icon = () => {
    switch (type) {
      case "weight":
        return "battery.svg";
      case "emission":
        return "footprint.svg";
      case "count":
        return "earth.svg";
      case "pickup":
        return "truck.svg";
      default:
        return "care.svg";
    }
  };

  return (
    <Flex
      pos="relative"
      flexDir="column"
      p="24px"
      boxShadow="0px 4px 8px 0px #00000033"
      bg="white"
      textAlign="center"
      justifyContent="space-between"
      h="100px"
      w="160px"
      borderRadius="10px"
      {...props}
    >
      <Box pos="absolute" top="-40px" left="35%" style={iconStyle}>
        <Image src={`/assets/dashboard/icons/${Icon()}`} w="60px" />
      </Box>
      <Box
        color="brand.green.200"
        fontFamily="zolo-header"
        fontSize="18px"
        fontWeight="bold"
      >
        {value}
      </Box>
      <Box color="brand.gray.400" fontSize="14px">
        {text}
      </Box>
    </Flex>
  );
};
