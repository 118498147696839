import { useState, useEffect } from "react";
import { UserService } from "../../../../service/user/user.service";
import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IconCustom } from "../../../shared/IconCustom";
import { CardCustom } from "../../../shared/CardCustom";
import { CardTitle } from "../../../shared/CardTitle";
import AlertDialog from "../../../shared/AlertDialog";
import { useAppSelector } from "../../../../util/hooks.util";
import { ButtonCustom } from "../../../form/controls/form.button";
import CancelIcon from "@mui/icons-material/Cancel";
import EmailIcon from "@mui/icons-material/Email";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import { Role } from "../../../../models/enum/role.enum";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { BranchService } from "../../../../service/company/branch.service";
import { useParams } from "react-router-dom";
import { BranchInviteUserModal } from "../branch-inviteUser.modal";
import { Separator } from "../../../shared/Separator";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { BranchUserService } from "../../../../service/company/branch-user.service";
import {
  errorToast,
  successToast,
} from "../../../../constants/toast.constants";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import { isInternalAdmin } from "../../../../util/zolo.util";

export const BranchDetailUserCard = ({}) => {
  const branchService = new BranchService();
  const branchUserService = new BranchUserService();

  const currentUser = useAppSelector((state) => state.userProfile);
  const { id: branchCompanyId } = useParams();

  const [users, setUsers] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [userToRevoke, setUserToRevoke] = useState("");
  const [revokeAlert, setRevokeAlert] = useState(false);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [userToDeactivate, setUserToDeactivate] = useState("");
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();

  const toast = useToast();

  const init = async () => {
    await fetchUsers();
    await fetchInvited();
  };

  const fetchUsers = async () => {
    const users = await branchUserService.getUsers(branchCompanyId);
    setUsers(users);
  };

  const fetchInvited = async () => {
    const list = await branchUserService.getInvitedList(branchCompanyId);
    setInvitedUsers(list);
  };

  useEffect(() => {
    init();
  }, []);

  const handleRevoke = async (email: string) => {
    await branchUserService
      .revokeInvite({ email, companyId: branchCompanyId })
      .then(async () => {
        setRevokeAlert(false);
        toast(successToast(`${email} invitation has been revoked.`));
        await fetchInvited();
      })
      .catch((err) => {
        console.log(err);
        toast(errorToast(`Something went wrong. Please try again.`));
      });
  };

  const handleDeactivate = async (email: string) => {
    await branchUserService
      .deactivate({ email, companyId: branchCompanyId })
      .then(async () => {
        alertOnClose();
        await fetchUsers();
        toast(successToast(`${email} has been deactivated.`));
      })
      .catch((err) => {
        console.log(err);
        toast(errorToast(`Something went wrong. Please try again.`));
      });
  };

  const handleActivate = async (email: string) => {
    await branchUserService
      .activate({ email, companyId: branchCompanyId })
      .then(async () => {
        await fetchUsers();
        toast(successToast(`${email} has been activated.`));
      })
      .catch((err) => {
        console.log(err);
        toast(errorToast(`Something went wrong. Please try again.`));
      });
  };

  const handleRoleSelect = async (roleName: string, email: string) => {
    await branchUserService
      .updateRole({ roleName, email, companyId: branchCompanyId })
      .then(() => {
        init();
        toast(successToast(`${email} has been updated to ${Role[roleName]}.`));
      })
      .catch((err) => {
        console.log(err);
        toast(errorToast(`Something went wrong. Please try again.`));
      });
  };

  const columns = [
    {
      header: "Email",
      accessor: "email",
      callback: (email, user) => {
        if (isInternalAdmin(user.email) && user.deleted) {
          return;
        }

        return (
          <Flex
            color={user.deleted ? "#A0AEBF" : undefined}
            flexDir="row"
            w="300px"
            gap="4px"
          >
            {user.deleted ? (
              <CancelIcon style={{ fontSize: "28px", color: "#A0AEBF" }} />
            ) : (
              <IconCustom fileName="check" />
            )}

            {email}
          </Flex>
        );
      },
    },
    {
      header: "",
      accessor: "roleName",
      callback: (role, user) => {
        if (isInternalAdmin(user.email) && user.deleted) {
          return;
        }

        return currentUser.role === Role.ADMIN &&
          user.email !== currentUser.email ? (
          <Flex w="150px" color={user.deleted ? "#A0AEBF" : undefined}>
            <Menu>
              <MenuButton
                as={Button}
                variant="ghost"
                rightIcon={<ChevronDownIcon />}
                fontWeight="normal"
                p={1}
                height="inherit"
              >
                {role}
              </MenuButton>
              <MenuList>
                {(Object.keys(Role) as Array<Role>).map((key, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      role !== Role[key] && handleRoleSelect(key, user.email);
                    }}
                  >
                    {Role[key]}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
        ) : (
          <Text p={1}>{role}</Text>
        );
      },
    },
    currentUser.role === Role.ADMIN && {
      accessor: "deleted",
      callback: (deleted, user) => {
        if (user.email === currentUser.email) {
          return;
        }
        if (isInternalAdmin(user.email) && user.deleted) {
          return;
        }

        return (
          <>
            {deleted ? (
              <Tooltip label="Reactivate" placement="bottom">
                <Box
                  color="gray"
                  cursor="pointer"
                  _hover={{ color: "brand.brown.100" }}
                >
                  <BoltOutlinedIcon
                    onClick={() => {
                      handleActivate(user.email);
                    }}
                  />
                </Box>
              </Tooltip>
            ) : (
              <Tooltip label="Deactivate" placement="bottom">
                <Box
                  color="gray"
                  cursor="pointer"
                  _hover={{ color: "brand.brown.100" }}
                >
                  <DeleteOutlineIcon
                    fontSize="small"
                    onClick={() => {
                      setUserToDeactivate(user.email);
                      alertOnOpen();
                    }}
                  />
                </Box>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <CardCustom gap="6px" px={{ base: "16px", md: "40px" }}>
        <Flex flexDir="row" justifyContent="space-between">
          <CardTitle fontSize="20px">Users</CardTitle>
          <ButtonCustom
            hidden={currentUser.role !== Role.ADMIN}
            onClick={() => setInviteOpen(true)}
            neutral
            style={{
              width: "120px",
              height: "30px",
              backgroundColor: "transparent",
            }}
          >
            <AddIcon />
            Invite user
          </ButtonCustom>
        </Flex>
        <TableContainer>
          <Table
            mt="24px"
            variant="simple"
            display={{ base: "none", md: "block" }}
          >
            <Tbody w="100%">
              {users.map((row, i) => (
                <Tr key={i}>
                  {columns.map((column, j) => (
                    <Td key={j} py={1} pl={j === 0 && 0} borderBottom="none">
                      {column.callback ? (
                        <>{column.callback(row[column.accessor], row)}</>
                      ) : (
                        <>{row[column.accessor]}</>
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>

          <Box mt="14px" display={{ base: "block", md: "none" }}>
            {users.map((row, i) => (
              <Flex
                my="4px"
                gap="4px"
                p="16px"
                border="1px solid lightGray"
                borderRadius="6px"
                flexDir="column"
                key={i}
              >
                {columns.map((column, j) => (
                  <Box key={j} p={column.accessor === "notes" && 0}>
                    {column.callback ? (
                      <Flex flexDir="row" gap="6px">
                        {column.callback(row[column.accessor], row)}
                      </Flex>
                    ) : (
                      <Flex flexDir="row" gap="6px">
                        {column.header} {row[column.accessor]}
                      </Flex>
                    )}
                  </Box>
                ))}
              </Flex>
            ))}
          </Box>

          <Box hidden={!invitedUsers.length}>
            <Divider my="26px" orientation="horizontal" />
            <Box>
              <Separator />
              <CardTitle fontSize="20px">
                Invited ({invitedUsers.length})
              </CardTitle>
            </Box>

            <Table mt="26px" hidden={!invitedUsers?.length} variant="simple">
              <Tbody>
                {invitedUsers.map((row, i) => (
                  <Tr px="0" key={i}>
                    <Flex flexDir="row" justifyContent="space-between">
                      <Td color="gray" px="0" py={1} borderBottom="none">
                        <Flex flexDir="row" gap="4px" alignItems="center">
                          <HelpOutlineOutlinedIcon />
                          {row.email}
                        </Flex>
                      </Td>

                      <Td
                        py={1}
                        borderBottom="none"
                        _hover={{ color: "brand.brown.100" }}
                        cursor="pointer"
                      >
                        <Tooltip label="Revoke" placement="bottom">
                          <DeleteOutlineIcon
                            fontSize="small"
                            onClick={() => {
                              setUserToRevoke(row.email);
                              setRevokeAlert(true);
                            }}
                          />
                        </Tooltip>
                      </Td>
                    </Flex>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </TableContainer>
      </CardCustom>
      <BranchInviteUserModal
        companyId={branchCompanyId}
        isOpen={inviteOpen}
        onClose={() => setInviteOpen(false)}
        onSubmit={fetchInvited}
      />
      <AlertDialog
        isOpen={revokeAlert}
        onClose={() => setRevokeAlert(false)}
        title={`Revoke invite for ${userToRevoke}`}
        colorScheme="red"
        confirmText="Revoke"
        onConfirm={() => handleRevoke(userToRevoke)}
      >
        Are you sure? You can't undo this action.
      </AlertDialog>
      <AlertDialog
        isOpen={alertIsOpen}
        onClose={alertOnClose}
        title={`Deactivate ${userToDeactivate}`}
        colorScheme="red"
        confirmText="Deactivate"
        onConfirm={() => handleDeactivate(userToDeactivate)}
      >
        Are you sure? You can't undo this action.
      </AlertDialog>
    </>
  );
};
