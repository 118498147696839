import {
  Flex,
  useToast,
  Image,
  Text,
  Box,
  Center,
  Spinner,
  Grid,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MagiclinkService } from "../../service/magiclink/magiclink.service";
import { update } from "../../store/slice/Account/user-profile.slice";
import { errorToast } from "../../constants/toast.constants";
import { getQueryParam } from "../../util/url.util";
import BasicLayout from "../../components/layout/basic.layout";

export const RegisterRedirect: React.FC<any> = (props: any) => {
  const magiclinkService = new MagiclinkService();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const complete = async (token: string | null) => {
    if (!token) {
      toast(errorToast("Failed token authentication. Please try again", 7500));
      navigate("/app/auth/login");
    }

    navigate("/app/auth/company/register");
  };

  const finishEmailRedirectLogin = () => {
    const magicCredential = getQueryParam("magic_credential");
    const isSocialLogin = getQueryParam("social_login");

    if (!magicCredential) navigate("/app/auth/login");

    if (magicCredential) {
      if (isSocialLogin) {
        magiclinkService.magicSocial?.oauth
          .getRedirectResult()
          .then(async (auth) => await complete(auth.magic.idToken));

        return;
      }

      magiclinkService.magic?.auth
        .loginWithCredential()
        .then((didToken) => complete(didToken));
    }
  };

  useEffect(() => {
    finishEmailRedirectLogin();
  }, []);

  return (
    <BasicLayout>
      <Flex flexDirection="column" gap="16px">
        <Flex textAlign="center" flexDirection="column" gap="6px">
          <Text fontSize="26px" fontWeight="700" color="brand.yellow.300">
            Re-imagine E-Waste Management
          </Text>
        </Flex>

        <Center>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="brand.green.200"
            color="brand.yellow.300"
            size="xl"
          />
        </Center>

        <Center>
          <Grid
            mt="20px"
            w={{ base: undefined, md: "45vw" }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            gap="12px"
          >
            <Box>
              <Image src="/assets/homepage-pickup.svg" alt="Pickup" />
            </Box>
            <Box color="white" fontSize="20px">
              <Text fontWeight="700" fontSize="26px">
                Single Touch Recycling With Zolo Single Touch
              </Text>
              Recycling is as easy as clicking a button. Upload your e-waste
              inventory and let us handle the complex processes. Your IT team
              can focus on what they do best while we take care of the rest.
            </Box>
          </Grid>
        </Center>
      </Flex>
    </BasicLayout>
  );
};
