import { Center, Container, Text } from "@chakra-ui/react";
import React, { useCallback } from "react";

import {
  DropEvent,
  DropzoneOptions,
  FileRejection,
  useDropzone,
} from "react-dropzone";

export interface Dropzone {
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;

  accept: any;
  maxFiles?: number;
  isUploaded?: boolean;
  filename?: string;
  isInvalid?: boolean;
  invalidInputMessage?: string;
  additionalText?: string;

  children?: any;
}

export const ImageUploadIcon: React.FC<Dropzone> = (payload: Dropzone) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: payload.onDrop,
    accept: payload.accept,
    maxFiles: payload.maxFiles ? payload.maxFiles : 0,
  } as any as DropzoneOptions);

  const { isInvalid } = payload;

  return (
    <>
      <Container onClick={open} _hover={{ cursor: "pointer" }}>
        <Center>{payload.children}</Center>
        <input className="dropzone-input" {...getInputProps()} />
      </Container>
      {isInvalid === true ? alert(1) : <></>}
    </>
  );
};
