import {
  Flex,
  SimpleGrid,
  Text,
  useToast,
  Image,
  Center,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BaseContainer from "../../components/form/base.container";
import { ButtonCustom } from "../../components/form/controls/form.button";
import { FormInput } from "../../components/form/controls/form.input";
import BasicLayout from "../../components/layout/basic.layout";
import { Loading } from "../../components/shared/Loading";
import { CompanyService } from "../../service/company/company.service";
import { IdentityService } from "../../service/identity/identity.service";
import TokenService from "../../service/identity/token.service";
import { MagiclinkService } from "../../service/magiclink/magiclink.service";
import { update } from "../../store/slice/Account/user-profile.slice";
import { tokenSlice } from "../../store/slice/Token/token.slice";
import { previewImageBlob } from "../../util/file.util";
import { AvatarCustom } from "../../components/form/avatar.custom";
import { FileUploadInput } from "../../components/form/controls/file-upload.input";
import { ellipsesText } from "../../util/string.util";
import { update as updateCompany } from "../../store/slice/Account/company.slice";
import { ForestBackground } from "../../components/shared/ForestBackground";
import { CountryDropdown } from "react-country-region-selector";
import { styled } from "@chakra-ui/react";
import { CountryDropdownInput } from "../../components/form/controls/country-dropdown.input";

const companyService = new CompanyService();
const magiclinkService = new MagiclinkService();
const tokenService = new TokenService();
const identityService = new IdentityService();

const RegisterCompany: React.FC<any> = (props: any) => {
  const [formState, setFormState] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [photoData, setPhotoData] = useState(null as any);
  const [photoUri, setPhotoUri] = useState("");
  const [uploadState, setUploadState] = useState({
    name: "",
    formData: {},
    uploaded: false,
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const toast = useToast();

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const onProfilePhotoUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file[0]);

    setPhotoData(formData);

    const imageUri = (await previewImageBlob(file[0])) as string;
    setPhotoUri(imageUri);

    setUploadState({
      name: file[0].name,
      formData: formData,
      uploaded: true,
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);
    const didToken = await magiclinkService.getIdToken();

    const payload = { ...formState, email };
    if (!payload.country) {
      payload.country = "Australia";
    }

    await companyService
      .registerCompany(didToken, payload, photoData)
      .then(async (userProfile) => {
        dispatch(update(userProfile));

        const accessToken = await tokenService
          .getAccessToken(didToken!)
          .catch((error) => {
            alert(error);
            navigate("/app/auth/login");
          });

        dispatch(tokenSlice.actions.update({ accessToken } as any));
        dispatch(
          updateCompany({
            country: userProfile.company.country,
            name: userProfile.company.name,
            isHeadquarters: true,
          })
        );

        navigate("/app/dashboard");
      })
      .catch(() => {
        alert("Error while registering company");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkAccess = async () => {
    setLoading(true);

    const didToken = await magiclinkService.getIdToken();
    if (!didToken) {
      setLoading(false);

      navigate("/app/auth/login");
    }

    const user = await identityService.getPublicUser(didToken!);

    if (!!user?.id?.length) {
      setLoading(false);
      navigate("/app/dashboard");
    }

    setEmail(email!);
    setLoading(false);
  };

  useEffect(() => {
    checkAccess();
  }, []);

  return (
    <BasicLayout>
      <ForestBackground />
      <Loading loading={loading} fill />
      <BaseContainer>
        <Center>
          <Image src="/logo.svg" alt="MagicHire Logo" width="110px" mb="16px" />
        </Center>

        <Flex pb="24px" flexDirection="row" mt="8px" gap={"16px"}>
          <AvatarCustom
            src={photoUri}
            widthxheight="80px"
            border={"0"}
            filter={"none"}
          />
          <Flex flexDirection="column" gap={"8px"} width={"100%"}>
            <Text fontSize={"14px"} fontWeight={"600"}>
              Company Logo
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap="8px" width={"100%"}>
              <FileUploadInput
                onlyButton
                uploadedLabel={ellipsesText(uploadState.name, 16)}
                type="picture"
                onDrop={onProfilePhotoUpload}
                accept={{ "image/*": [".jpeg", ".jpg", ".png"] }}
                maxFiles={1}
                isUploaded={uploadState.uploaded}
                additionalText="We only accept .doc, .docx, .pdf"
                invalidInputMessage="Please take a photo or upload one."
              />
            </SimpleGrid>
          </Flex>
        </Flex>

        <form onSubmit={onSubmit}>
          <Flex flexDirection={"column"} gap={"16px"}>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap="16px">
              <FormInput
                name="firstName"
                title="First Name"
                placeholder="Enter first name"
                onChange={onInputChange}
                required
              />
              <FormInput
                name="lastName"
                title="Last Name"
                placeholder="Enter last name"
                onChange={onInputChange}
                required
              />
            </SimpleGrid>

            <FormInput
              name="companyName"
              title="Company Name"
              placeholder="e.g. Acme Inc"
              onChange={onInputChange}
              required
            />

            <CountryDropdownInput
              name="country"
              defaultValue="Australia"
              onChange={onInputChange}
            />

            <FormInput
              name="url"
              title="Website"
              placeholder="e.g. www.company.com"
              onChange={onInputChange}
              type="url"
            />
            <ButtonCustom
              type="submit"
              style={{ width: "100%", marginTop: "12px" }}
            >
              Complete Profile
            </ButtonCustom>
          </Flex>
        </form>
      </BaseContainer>
    </BasicLayout>
  );
};

export default RegisterCompany;
