import { createSlice } from "@reduxjs/toolkit";

export interface Job {
  sortColumn: { accessor: string; direction: 0 | 1 };
}

export const initialState: Job = { sortColumn: { accessor: "", direction: 0 } };

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    update: (state, params) => {
      const f = params.payload as Job;

      state.sortColumn = f.sortColumn;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = jobSlice.actions;

export default jobSlice.reducer;
