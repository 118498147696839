import { useEffect, useState } from "react";
import { DropoffService } from "../../service/company/dropoff.service";
import {
  Box,
  Flex,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Badge,
  Text,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ButtonCustom } from "../../components/form/controls/form.button";
import { DropoffModal } from "../../components/app/dropoff/dropoff.modal";
import AlertDialog from "../../components/shared/AlertDialog";
import { convertIsoTime } from "../../util/date.util";
import { downloadCsvToMachine } from "../../util/file.util";
import { DownloadIcon } from "@chakra-ui/icons";
import { ToggleInput } from "../../components/form/controls/toggle.input";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { TableContainer } from "../../components/shared/table/TableContainer";
import PopoverCustom from "../../components/shared/PopoverCustom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Role } from "../../models/enum/role.enum";
import { ProfileIncompleteModal } from "../../components/app/account/profile/profile-incomplete.modal";
import { UserService } from "../../service/user/user.service";
import { TableContainerMobile } from "../../components/shared/table/TableContainerMobile";
import { EmptyContent } from "../../components/shared/EmptyContent";

const naRender = (value) => value ?? "-";
const statusBadge = (status) => {
  const statusColors = {
    PROCESSED: "green",
    DELAYED: "red",
    PROCESSING: "orange",
  };
  return <Badge colorScheme={statusColors[status]}>{status}</Badge>;
};

export const Dropoffs = ({}) => {
  const dropoffService = new DropoffService();
  const userService = new UserService();

  const [dropoffs, setDropoffs] = useState([]);
  const [completedDropoffs, setCompletedDropoffs] = useState([]);

  const [profileIncomplete, setProfileIncomplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [toggled, setToggled] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [profileIncompleteModalOpen, setProfileIncompleteModalOpen] =
    useState(false);

  const [selectedDropoffId, setSelectedDropoffId] = useState("");

  const isLargeDisplay = useBreakpointValue({
    base: false,
    sm: false,
    md: true,
    lg: true,
    xl: true,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userId, role } = useSelector((state: RootState) => state.userProfile);

  const init = async () => {
    await dropoffService
      .getList()
      .then((dropoffs) => {
        setDropoffs(
          dropoffs.filter((x) => x.status.toString() !== "PROCESSED")
        );
        setCompletedDropoffs(
          dropoffs.filter((x) => x.status.toString() === "PROCESSED")
        );
      })
      .finally(() => setLoading(false));

    await userService.profileComplete().then((resp) => {
      setProfileIncomplete(!resp.companyComplete || !resp.profileComplete);
    });
  };

  const handleExport = async () => {
    await dropoffService.exportToCsv().then((csvData) => {
      downloadCsvToMachine(csvData, "inventory-export.csv");
    });
  };

  const handleDownload = async (id: string) => {
    const resp = await dropoffService.downloadFile({ id }).catch((err) => {
      console.log(err);
    });

    const fileData = resp.data.data;
    const blob = new Blob([new Uint8Array(fileData)], {
      type: resp.contentType,
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${resp.filename}`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleDelete = async (id: string) => {
    await dropoffService
      .delete({ id })
      .then(() => init())
      .catch((err) => {
        console.log(err);
      })
      .finally(onClose);
  };

  const columns = [
    {
      header: "Pick-up date",
      accessor: "requestDate",
      callback: (requestDate: Date) => <>{convertIsoTime(requestDate)}</>,
    },
    {
      header: "Address",
      accessor: "addressName",
      width: '300px',
      callback: (addressName) =>
        addressName?.length && (
          <PopoverCustom
            width="300px"
            trigger={
              <Box
                w="100%"
                display="flex"
                alignItems="center"
                height="100%"
                _hover={{
                  bgColor: "gray.200",
                }}
                cursor="pointer"
              >
                <Box
                  maxWidth="100%"
                  flex="1"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {addressName}
                </Box>
              </Box>
            }
          >
            <Box
              dangerouslySetInnerHTML={{
                __html: addressName.replace(/\n/g, "<br />"),
              }}
            ></Box>
          </PopoverCustom>
        ),
    },
    {
      header: "Source",
      accessor: "source",
      callback: (source: string) => <>{naRender(source?.toUpperCase())}</>,
    },
    {
      header: "Status",
      accessor: "status",
      callback: (status) => statusBadge(status),
    },
    {
      header: "Notes",
      accessor: "notes",
      callback: (notes) =>
        notes?.length && (
          <PopoverCustom
            width="300px"
            trigger={
              <Box
                w="100%"
                display="flex"
                alignItems="center"
                height="100%"
                px={3}
                _hover={{
                  bgColor: "gray.200",
                }}
                cursor="pointer"
              >
                <Box
                  maxWidth="100%"
                  flex="1"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {notes}
                </Box>
              </Box>
            }
          >
            <Box
              dangerouslySetInnerHTML={{
                __html: notes.replace(/\n/g, "<br />"),
              }}
            ></Box>
          </PopoverCustom>
        ),
    },
    {
      header: "",
      accessor: "id",
      callback: (id: string, dropoff) => (
        <Flex
          w="full"
          fontSize="22px"
          cursor="pointer"
          color="gray.500"
          flexDir="row"
          justifyContent="flex-end"
          gap="16px"
        >
          {dropoff.source ? (
            <Tooltip label="Download file">
              <DownloadIcon
                _hover={{ color: "#FFDF6C" }}
                onClick={() => handleDownload(id)}
              />
            </Tooltip>
          ) : (
            <Box w="22px"></Box>
          )}

          {(role === Role.ADMIN || dropoff.userId === userId) && (
            <Tooltip label="Delete">
              <Box _hover={{ color: "red" }}>
                <DeleteIcon
                  onClick={() => {
                    setSelectedDropoffId(id);
                    onOpen();
                  }}
                />
              </Box>
            </Tooltip>
          )}
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    // fetch dropoffs
    init();
  }, []);

  return (
    <>
      <Flex flexDirection="column">
        <Flex
          my="16px"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <ToggleInput
            style={{ justifyContent: "flex-end" }}
            leftLabel="Progress"
            rightLabel="Complete"
            onChange={(e) => {
              setToggled(e.target.checked);
            }}
          />

          <Flex flexDir="row" gap="6px">
            <ButtonCustom
              onClick={() => {
                if (profileIncomplete) {
                  setProfileIncompleteModalOpen(true);
                  return;
                }

                setModalOpen(true);
              }}
            >
              <AddIcon />
              Request pick-up
            </ButtonCustom>
            <ButtonCustom
              disabled={!dropoffs?.length}
              neutral
              onClick={handleExport}
            >
              Export to csv
              <DownloadIcon ml="6px" />
            </ButtonCustom>
          </Flex>
        </Flex>

        {isLargeDisplay ? (
          <TableContainer
            isEmpty={
              !(toggled ? completedDropoffs : dropoffs)?.length && !loading
            }
            columns={columns}
            hidden={!(toggled ? completedDropoffs : dropoffs)?.length}
            emptyChildren={
              <Flex
                justifyContent="center"
                alignItems="center"
                minH="100%"
                minW="100%"
                border="1px solid #ECECEC"
                borderRadius="lg"
              >
                <EmptyContent
                  title={
                    toggled ? "No completed pick-ups" : "No current pick-ups"
                  }
                  description={
                    toggled
                      ? "All completed pick-ups will show here."
                      : "When a new pick-up request is created it will show here."
                  }
                />
              </Flex>
            }
          >
            <Tbody>
              {(toggled ? completedDropoffs : dropoffs).map((row, i) => (
                <Tr key={i}>
                  {columns.map((column, j) => (
                    <Td key={j} h="50px" p={column.accessor === "notes" && 0}>
                      {column.callback ? (
                        <>{column.callback(row[column.accessor], row)}</>
                      ) : (
                        <>{row[column.accessor]}</>
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </TableContainer>
        ) : (
          <TableContainerMobile
            columns={columns}
            data={toggled ? completedDropoffs : dropoffs}
          />
        )}
      </Flex>
      <DropoffModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={() => {
          init();
          setModalOpen(false);
        }}
      />
      <ProfileIncompleteModal
        isOpen={profileIncompleteModalOpen}
        onClose={() => setProfileIncompleteModalOpen(false)}
      />
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        title="Delete Request"
        colorScheme="red"
        confirmText="Delete"
        onConfirm={() => handleDelete(selectedDropoffId)}
      >
        Are you sure? You can't undo this action.
      </AlertDialog>
    </>
  );
};
