import { createSlice } from "@reduxjs/toolkit";
import { InventoryBulkFilter } from "../../../models/filter/inventory-bulk.filter";

export interface Inventory {
  filter: InventoryBulkFilter;
  jobFilter: InventoryBulkFilter;
  sortColumn?: { accessor: string; direction: 0 | 1 };
  pageSize: number;
  jobSortColumn?: { accessor: string; direction: 0 | 1 };
  jobPageSize: number;
  formId?: string;
}

export const initialState: Inventory = {
  filter: {},
  jobFilter: {},
  pageSize: 100,
  jobPageSize: 10,
};

export const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    updateFilter: (state, params) => {
      const f = params.payload as InventoryBulkFilter;
      state.filter = f;
    },
    updateJobFilter: (state, params) => {
      const f = params.payload as InventoryBulkFilter;
      state.jobFilter = f;
    },
    updateSort: (state, params) => {
      const f = params.payload;
      state.sortColumn = f.sortColumn;
    },
    updateJobSort: (state, params) => {
      const f = params.payload;
      state.jobSortColumn = f.jobSortColumn;
    },
    updatePageSize: (state, params) => {
      const f = params.payload;
      state.pageSize = f.pageSize;
    },
    updateJobPageSize: (state, params) => {
      const f = params.payload;
      state.jobPageSize = f.pageSize;
    },
    updateActiveForm: (state, params) => {
      const f = params.payload;
      state.formId = f.formId;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateFilter,
  updateJobFilter,
  updatePageSize,
  updateJobPageSize,
  updateJobSort,
  updateSort,
  updateActiveForm,
} = inventorySlice.actions;

export default inventorySlice.reducer;
