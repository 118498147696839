import { DownloadIcon } from "@chakra-ui/icons";
import { Flex, Box, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { ReportService } from "../../../service/job/report.service";
import { useState } from "react";
import { errorToast } from "../../../constants/toast.constants";

export const JobDetailHeader = (props: Props) => {
  const reportService = new ReportService();

  const { id, inventoryCount } = props;
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const handleAcknowledgement = async () => {
    setLoading(true);
    await reportService
      .downloadAcknowledgementReport(id)
      .then((resp) => {
        const blob = new Blob([new Uint8Array(resp.data.data)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `acknowledgement-report-${id}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(() => {
        toast(errorToast("Failed to download pick-up report"));
      })
      .finally(() => setLoading(false));
  };

  const handleItad = async () => {
    setLoading(true);
    await reportService
      .downloadItadReport(id)
      .then((resp) => {
        const blob = new Blob([new Uint8Array(resp.data.data)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `greenguard-report-${id}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(() => {
        toast(errorToast("Failed to download GreenGuard™ report"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Flex flexDirection="row" gap="16px">
      <Box>
        <ButtonCustom
          hidden={!inventoryCount}
          neutral
          style={{ marginRight: "6px" }}
          onClick={handleAcknowledgement}
          disabled={loading}
        >
          Pick-up report (.xlsx)
          <DownloadIcon ml="6px" />
        </ButtonCustom>
        <ButtonCustom
          hidden={!inventoryCount}
          onClick={handleItad}
          disabled={loading}
        >
          GreenGuard™ Report
          <DownloadIcon ml="6px" />
        </ButtonCustom>
      </Box>
    </Flex>
  );
};

interface Props {
  id: string;
  inventoryCount?: number;
}
